import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faPinterest, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Modal from 'react-modal';
import axios from 'axios';
import './saveModal.scss'
import { faMailBulk, faTimesCircle, faLink, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Close } from "./close";
import Loading from "react-loading";

export interface ShareModalProps {
    open: any;
    close: any;
    id: string
    brand: any;
    design: any;
}

export interface ShareModalState {
    copy: string;
    patterns_api: any;
    modalIsOpen: boolean;
    loading: boolean;
}


const styleModal = {
    content: {
        width: '30vw',
        maxHeight : '90vh',
        minHeight: '61vh',
        top: '50%',
        transform: 'translateY(-50%)'
    }
} as Modal.Styles

class ShareModal extends React.Component<ShareModalProps, ShareModalState> {
    constructor(props: ShareModalProps) {
        super(props);
        this.state = {
            copy: "Copy",
            patterns_api: {},
            modalIsOpen: false,
            loading: false
        };


        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handlePDF = this.handlePDF.bind(this);
        this.copy = this.copy.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.design)
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
        this.props.close();
    }

    handlePDF(url: string) {
        this.setState({
            loading: true
        })

        setTimeout(() => {
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank'
            link.click()
            this.setState({
                loading: false
            })
        }, 2500)
    }


    copy(e: any) {
        e.preventDefault();
        (document.querySelector('.url') as any).select();
        document.execCommand('copy');
        this.setState({
            copy: "Copied"
        })

        setTimeout(() => {
            this.setState({
                copy: "Copy"
            })
        }, 3500)
    }

    render() {
        return (
            <Modal
                isOpen={this.props.open}
                onRequestClose={this.closeModal}
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={styleModal}
                portalClassName="save-modal-portal"
            >
                <div className="save-modal">
                    <div className="close__button" onClick={this.closeModal}>
                        <Close />
                    </div>
                    <h2>Share your design</h2>
                    {/* <p>Well, that’s a stunning design! Just copy the link, download a PDF or send the design via email.</p> */}
                    <div className="type pattern">
                        <div className="share">
                            <a target="_blank" className="fb" href={`http://www.facebook.com/sharer.php?u=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? this.props.brand.subdomain : ''}/design/${this.props.id}&p[title]=Mosaic+Creator`}><FontAwesomeIcon size="2x" icon={faFacebook} /> Facebook</a>
                            <a target="_blank" className="pin" href={`http://pinterest.com/pin/create/button/?url=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? this.props.brand.subdomain : ''}/design/${this.props.id}&description=Mosaic+Creator&media=${this.props.design.design_share}`}><FontAwesomeIcon size="2x" icon={faPinterest} /> Pinterest</a>
                            <a target="_blank" className="lin" href={`https://www.linkedin.com/shareArticle?mini=true&url=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? this.props.brand.subdomain : ''}/design/${this.props.id}&title=Mosaic+Creator&media=${this.props.design.design_share}`}><FontAwesomeIcon size="2x" icon={faLinkedin} /> LinkedIn</a>
                            <a target="_blank" className="tt" href={`https://twitter.com/intent/tweet?text=Look at what I created on the ${this.props.brand.name} Mosaic Creator! All custom creations are assembled by hand in the USA with just a two week production time. %23EmpowerImagination %23DesignWithoutLimits http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? this.props.brand.subdomain : ''}/design/${this.props.id}`}><FontAwesomeIcon size="2x" icon={faTwitter} /> Twitter</a>
                            <a target="_blank" className="email" href={`mailto:?subject=Check out my custom design!&body=Look at what I created on the ${this.props.brand.name} Mosaic Creator! All custom creations are assembled by hand in the USA with just a two week production time.%0A#EmpowerImagination #DesignWithoutLimits%0Ahttp://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? this.props.brand.subdomain : ''}/design/${this.props.id}`}><FontAwesomeIcon size="2x" icon={faMailBulk} /> Email</a>
                        </div>
                        <p>Or copy a link</p>
                        <div className="input-group">
                            <input type="text" className="url" onChange={() => { }} value={`https://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? `${this.props.brand.subdomain}` : ''}/design/${this.props.id}`} />
                            <a href="#" className="copy_url" onClick={this.copy}>{this.state.copy}</a>
                        </div>
                    </div>
                    {/* <div className="close__button_bottom" onClick={this.closeModal}>Close</div> */}
                </div>
            </Modal>
        );
    }
}

export default ShareModal;