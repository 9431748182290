import React from "react";
import Modal from 'react-modal';
import parse from 'html-react-parser';

import { RouteComponentProps } from "react-router";
import axios, { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faDownload, faShare } from "@fortawesome/free-solid-svg-icons";
import OrderModal from "../components/orderModal";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import canvg from 'canvg';
import Raphael from 'raphael';
import Loading from "react-loading";
import { Kitchen } from "./room-preview-kitchen";

// import '../App.css';
import './order.scss';
import './repeater.scss'
import Preview from "../components/preview";
import { Close } from "../components/close";

export interface OrderProps {
    // id: string,
    brand: any;
}

export interface OrderState {
    someHtml: any;
    svg: any;
    defs: any;
    materials: any;
    grout: any
    pattern: any
    orderModalOpen: any;
    confModal: any;
    design: any;
    view: string;
    loading: boolean;
    sample: boolean;
    orderSampleSend: boolean;
}

const APPLICATION = ['wall', 'floor', 'wet', 'pool', 'grout']

class Order extends React.Component<OrderProps, OrderState> {
    private materials: any = "";
    private previewComponent = React.createRef<Preview>()

    constructor(props: OrderProps) {
        super(props)

        this.state = {
            someHtml: '',
            svg: '',
            defs: '',
            materials: [],
            grout: {},
            pattern: {},
            orderModalOpen: false,
            confModal: false,
            design: {},
            view: 'single',
            loading: false,
            sample: false,
            orderSampleSend: false
        }
        this.quote = this.quote.bind(this);
        this.sample = this.sample.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleSingleClick = this.handleSingleClick.bind(this);
        this.handleRepeatClick = this.handleRepeatClick.bind(this);
        this.handleKitchenClick = this.handleKitchenClick.bind(this);
        this.handleEdit = this.handleEdit.bind(this);




    }

    public downloadURI(uri: any) {
        var link = document.createElement("a");
        link.target = '_blank';
        link.href = uri;
        link.click();
    }


    public handleDownload() {
        this.downloadURI(this.state.design.design_pdf);
    }

    public handleEdit() {
        localStorage.setItem('patterns', JSON.stringify({
            'pattern': {
                ...this.state.pattern,
                // zones: this.state.materials.length
            }
        }))
        localStorage.setItem('materials', this.materials)
        localStorage.setItem('grouts', JSON.stringify(this.state.grout))
        localStorage.setItem('defs', JSON.stringify(this.state.defs))

        window.location.replace(`/${this.props.brand.subdomain}`)
    }

    public tries = 0;

    public getRandom(allChips: any, materialsChips: any, chip?: any): number {
        const random = Math.floor(Math.random() * allChips.length)
        const randomChip = allChips[random];
        const prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 15) || materialsChips.includes(randomChip - 16);
        const nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 15) || materialsChips.includes(randomChip + 16);
        if (this.tries < 30 && (prevChip || nextChip)) {
            this.tries = this.tries + 1;
            // console.log(this.tries);
            return this.getRandom(allChips, materialsChips);
        }
        this.tries = 0;
        return randomChip;

    }

    public randomNumber(allChips: any, used: any) {
        let maxTries = 10;
        const random = allChips[Math.floor(Math.random() * allChips.length)]
        if (used.length && used.includes(random) && this.tries < maxTries) {
            this.randomNumber(allChips, used);
        }
        this.tries = 0;
        return random
    }

    public count(arr: any[]) {
        return arr.reduce((prev: any, curr: any) => (prev[curr] = ++prev[curr] || 1, prev), {})
    }


    public radnomizeMaterialInZone(materialKey?: any) {
        const materials = JSON.parse(this.materials);
        const zones = this.count(Object.keys(materials).map((key) => key.replace(new RegExp("_[0-9]{1,2}", "g"), '')));
        
        const tiles = document.querySelectorAll('.svg__wrapper > div:not(.kitchen-wrapper), .injected-svg svg')

        Object.keys(zones).forEach((materialKey: any) => {

            
            const zone = materialKey.replace('material-', '');
            const materialsInZone = Object.keys(materials).filter((key) => key.match(materialKey ? materialKey.replace(new RegExp("_[0-9]{1,2}", "g"), '') : ''));
            
            tiles.forEach((tile, idx) => {
                let chips: any;

                if (tile) {
                    chips = tile.querySelectorAll(`g[id="zone-${zone}"] circle, g[id="zone-${zone}"] path, g[id="zone-${zone}"] ellipse, g[id="zone-${zone}"] rect`);
                }
                ;

                chips.forEach((el: Element) => {
                    (el as HTMLElement).style.fill = `#ccc`;
                    (el as HTMLElement).setAttribute('class', ``);
                })

                if (materialsInZone.length === 1 && materials[materialKey]) {
                    
                    let url = materials[materialKey].src.replace(`${process.env.REACT_APP_API_URL}/storage/uploads/`, '')
                    let chipsUrl = materials[materialKey].chips;

                    chipsUrl = this.IsJsonString(chipsUrl) ? JSON.parse(chipsUrl) : chipsUrl;

                    if (chipsUrl && chipsUrl.length) {
                        url = chipsUrl[Math.floor(Math.random() * chipsUrl.length)];
                    }

                    chips.forEach((el: Element) => {
                        (el as HTMLElement).style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                        (el as HTMLElement).setAttribute('class', materialKey);
                    })    

                    return false;
                }

                const chipsAmount = chips.length;
                let allChips: any = [];
                for (let index = 1; index <= chipsAmount; index++) {
                    allChips.push(index)
                }
                setTimeout(() => {

                    materialsInZone.map((key: any) => {
                        
                        const percent = materials[key].percent
                        let amountOfChips = Math.ceil((chipsAmount) * (percent) * 0.01)
                        if (amountOfChips > allChips.length) {
                            amountOfChips = allChips.length
                        }
                        const usedId: any = [];

                        for (let i = 0; i < amountOfChips; i++) {

                            const random = this.randomNumber(allChips, usedId)
                            usedId.push(random)
                            let url = materials[key].src.replace(`${process.env.REACT_APP_API_URL}/storage/uploads/`, '')
                            let chipsUrl = materials[key].chips;

                            chipsUrl = this.IsJsonString(chipsUrl) ? JSON.parse(chipsUrl) : chipsUrl;

                            if (chipsUrl && chipsUrl.length) {
                                url = chipsUrl[Math.floor(Math.random() * chipsUrl.length)];
                            }

                            const chipSingle = chips[random - 1] || chips[random];
                            if (chipSingle) {
                                chipSingle.style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                                chipSingle.setAttribute('class', key);
                                allChips.splice(allChips.findIndex((item: any) => item === random), 1)
                            }
                        }

                    });

                }, 400)
            })
        })


    }

    public radnomizeMaterial(item?: any, key?: string) {
        const tiles = document.querySelectorAll('.svg__wrapper > div:not(.kitchen-wrapper), .injected-svg svg')
        const tile = document.querySelector('.svg__wrapper > div')
        let chips: any;
        if (tile) {
            chips = tile.querySelectorAll('g circle:not(#grout), g rect:not(#grout), g path:not(#grout), g ellipse:not(#grout), g polygon:not(#grout)');
        }
        chips.forEach((el: Element) => {
            (el as HTMLElement).style.fill = `#ccc`;
            (el as HTMLElement).setAttribute('class', ``);
        })
        const chipsAmount = chips.length;



        setTimeout(() => {
            tiles.forEach((tile, idx) => {
                // if (idx > 0) return false;
                if (tile) {
                    chips = tile.querySelectorAll('g circle:not(#grout), g rect:not(#grout), g path:not(#grout), g ellipse:not(#grout), g polygon:not(#grout)');
                }
                chips.forEach((el: Element) => {
                    (el as HTMLElement).style.fill = `#ccc`;
                    (el as HTMLElement).setAttribute('class', ``);
                })

                let materials = this.state.materials || {};
                const chipsPerMaterials = [];
                let allChips: any = [];

                for (let index = 1; index <= chipsAmount; index++) {
                    allChips.push(index)
                }

                for (let index = 0; index < this.state.materials.length; index++) {

                    chipsPerMaterials.push(Math.ceil((chipsAmount) * (materials[index].percent) * 0.01))
                }

                let materialsChips: any = {}
                for (const key in materials) {
                    if (materials.hasOwnProperty(key)) {
                        materialsChips = {
                            ...materialsChips,
                            [key]: []
                        }
                    }
                }

                const chunk = allChips.length / 4;

                let temparray: any[] = [];

                for (let i = 0; i < allChips.length; i += chunk) {
                    let chips = allChips;
                    let myChunk = chips.slice(i, i + chunk);
                    temparray.push(myChunk);
                }

                chipsPerMaterials.forEach((num: number, idx) => {
                    for (let index = 0; index < temparray.length; index++) {

                        let part: any[] = temparray[index];
                        for (let index = 0; index < num / 4; index++) {
                            let randomChip: number = this.getRandom(part, materialsChips[idx]);

                            part = part.filter((chip: any) => chip !== randomChip)
                            materialsChips[idx].push(randomChip);
                        }
                        temparray[index] = part
                    }

                });
                if (tile) {
                    Object.keys(materialsChips).forEach((key) => {

                        const arr = materialsChips[key];


                        let chipsEl = tile.querySelectorAll(arr.map((id: number) => {
                            return `#chip-${id}`
                        }).join(', '))

                        let url = materials[key] ? materials[key].src.replace('${process.env.REACT_APP_API_URL}/storage/uploads/', '') : item.src.replace(`materials/${key}/', ''`)

                        chipsEl.forEach((el: Element) => {
                            let chips = materials[key].chips;

                            chips = this.IsJsonString(chips) ? JSON.parse(chips) : chips;


                            if (chips && chips.length) {

                                url = chips[Math.floor(Math.random() * chips.length)];
                            }

                            (el as HTMLElement).style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                            (el as HTMLElement).setAttribute('class', key);
                        })
                    })
                }
            })
        }, 400)
    }

    public IsJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    public componentDidMount() {
        this.setState({
            loading: true
        })
        axios.get(`${process.env.REACT_APP_API_URL}/api/design/${(this.props as any).match.params.id}`).then((data) => {
            if (data.data.design_image) {
                // return parse(data.data.svg);
                this.materials = data.data.materials;
                this.getMaterials(data.data.materials);
                this.getGrout(data.data.grout);
                // console.log(data.data);
                const defs = data.data.defs;
                // console.log(data.data.pattern);

                this.setState({
                    svg: data.data.svg,
                    defs: JSON.parse(defs),
                    pattern: data.data.pattern,
                    design: data.data,
                    view: 'single',
                    loading: false,
                    // materials: data.data.matrials
                });

                const materials = JSON.parse(data.data.materials);
                // if (this.state.pattern['pattern'].zones === 0) {

                setTimeout(() => {
                    let tileBackground = document.querySelectorAll('.svg__wrapper .injected-svg');
                    tileBackground.forEach((tile: any) => {
                        const grout = tile.querySelector('#grout');
                        if (grout) {
                            // console.log(this.state.grout.color);

                            tile.querySelector('#grout').style.fill = this.state.grout.color !== '#ffffff' ? this.state.grout.color : 'transparent';
                        }
                    })
                    if (this.state.pattern.randomize) {
                        this.radnomizeMaterial();
                    } else {
                        this.radnomizeMaterialInZone();
                    }
                    let grouts = document.querySelectorAll('.svg__wrapper #grout');

                    grouts.forEach(el => {
                        (el as HTMLElement).style.fill = this.state.grout.color !== '#ffffff' ? this.state.grout.color : 'transparent';
                        (el as HTMLElement).classList.remove('none');
                        if (this.state.view === 'single' && this.state.grout.color === '') {
                            (el as HTMLElement).classList.add('none');
                            (el as HTMLElement).style.fill = 'transparent';
                        }
                    })
                    let repeatBackground = document.querySelector('.svg__wrapper');
                    if (repeatBackground) {
                        (repeatBackground as HTMLElement).style.backgroundColor = this.state.grout.color;
                    }
                    const url = window.location.href;
                    const hash = url.substring(url.indexOf('#'));
                    // console.log(hash);

                    if (hash === '#order') {
                        this.setState({
                            orderModalOpen: true
                        })
                    }
                    if (this.previewComponent && this.previewComponent.current) {
                        this.previewComponent.current.update();
                    }
                    this.forceUpdate()
                }, 1500)

            }
        });
    }

    public getMaterials(materials: any) {
        const retMaterials: any[] = [];
        if (materials) {
            materials = JSON.parse(materials);
            Object.keys(materials).sort().forEach((key) => {
                const element = materials[key];
                const zone = key.replace(/material-/, '').replace(new RegExp("_[0-9]{1,2}", "g"), '');
                element.zone = zone;
                retMaterials.push(element);
            })
        }
        console.log(retMaterials);

        this.setState({
            materials: retMaterials
        })
    }

    public quote() {
        this.setState({
            sample: false,
            orderModalOpen: true
        })
    }
    public sample() {
        this.setState({
            sample: true,
            orderModalOpen: true
        })
    }

    private getThankYouMessage() {
        if (this.state.sample) {
            return "Our sample team will send you all of the materials included in your design promptly!"
        }

        if (!this.state.sample && this.state.orderSampleSend) {
            return "Our sample team will respond with a quote promptly and send you a sample of all materials you selected!"
        }

        if (!this.state.sample) {
            return "Our professional team will respond with a quote shortly!"
        }
    }

    public handleOrder(form: any) {
        const id = (this.props as any).match.params.id;
        const brandId = this.props.brand.id || null;

        this.setState({
            orderSampleSend: form.address
        });
        form = { ...form, thankYouMessage: this.getThankYouMessage() }
        axios.post(`${process.env.REACT_APP_API_URL}/api/save-order`, {
            id,
            form,
            brand_id: brandId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {

            this.setState({
                orderModalOpen: false,
                confModal: true
            })

        })
    }

    public getGrout(grout: any) {
        // let grout: any = grout;
        grout = JSON.parse(grout);
        this.setState({
            grout
        })
    }

    public handleSingleClick() {
        let svgWrapper = document.querySelector('.svg__wrapper');
        if (svgWrapper) {
            svgWrapper.classList.remove('kitchen');
            svgWrapper.classList.remove('repeat');
            svgWrapper.classList.add('single')
        }
        this.setState({
            view: 'single'
        })
    }
    public handleRepeatClick() {
        let svgWrapper = document.querySelector('.svg__wrapper');
        if (svgWrapper) {
            svgWrapper.classList.add('repeat');
            svgWrapper.classList.remove('single')
            svgWrapper.classList.remove('kitchen')
        }
        this.setState({
            view: 'repeat'
        })
    }

    public handleKitchenClick() {
        let svgWrapper = document.querySelector('.svg__wrapper');
        if (svgWrapper) {
            svgWrapper.classList.add('kitchen');
            svgWrapper.classList.remove('repeat')
            svgWrapper.classList.remove('single')
        }
        this.setState({ view: 'kitchen' })
    }

    public groupBy(xs: any, key: string) {
        return xs.reduce((rv: any, x: any) => {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };

    render() {

        const style = {
            backgroundColor: `${this.state.grout.color}`
        }
        if (this.state.loading) {
            return (
                <div className="loading-page">
                    <Loading type="spin" color="#000" />
                </div>
            )
        }

        const materials = this.groupBy(this.state.materials.sort(), 'zone');
        return (
            <div className="order-view" id="capture">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 0 }} width="0" height="0">
                    <defs>
                        <clipPath id="diamond-mask">
                            <path id="material-placeholder-diamond-4" d="M197.179,29.689a68.628,68.628,0,0,1-16.233-12.31A75.6,75.6,0,0,1,171.7,6.245c-.044-.065-.088-.129-.132-.194-.135-.2-.268-.406-.4-.609q-.24-.368-.47-.734c-.065-.1-.13-.206-.193-.309-.7-1.124-1.338-2.237-1.907-3.333a2.169,2.169,0,0,0-3.735,0A68.641,68.641,0,0,1,152.552,17.3a68.169,68.169,0,0,1-16.527,12.456,2.206,2.206,0,0,0-.008,3.365,68.152,68.152,0,0,1,16.535,12.459,68.517,68.517,0,0,1,12.337,16.3l.029.054a2.191,2.191,0,0,0,3.373.484A67.654,67.654,0,0,1,180.9,45.582,67.683,67.683,0,0,1,197.742,32.97a2.19,2.19,0,0,0-.562-3.281" transform="translate(-135.243)" fill="#333" />
                        </clipPath>
                        <clipPath id="diamond-mask-small">
                            <path id="material-placeholder-diamond-4" d="M164.736,14.137a32.68,32.68,0,0,1-7.73-5.862,36,36,0,0,1-4.405-5.3l-.063-.093c-.065-.1-.128-.193-.19-.29q-.114-.175-.224-.35l-.092-.147c-.332-.535-.637-1.065-.908-1.587a1.033,1.033,0,0,0-1.779,0,32.686,32.686,0,0,1-5.86,7.732,32.461,32.461,0,0,1-7.87,5.931,1.051,1.051,0,0,0,0,1.6,32.454,32.454,0,0,1,7.874,5.933,32.627,32.627,0,0,1,5.875,7.761l.014.025a1.043,1.043,0,0,0,1.606.23,32.216,32.216,0,0,1,6.006-8.017A32.23,32.23,0,0,1,165,15.7a1.043,1.043,0,0,0-.268-1.563" transform="translate(-135.243)" fill="#333" />
                        </clipPath>
                    </defs>
                </svg>
                <div className="prev">
                    <h2>{this.state.pattern.name} Custom Mosaic</h2>
                    <h4>{`Designed ${this.state.design.first_name ? `by ${this.state.design.first_name} ${this.state.design.last_name[0]}.` : ''} on ${this.state.design.created_at}`}</h4>
                    <h5>{this.state.design.project_name ? 'For ' + this.state.design.project_name : ''}</h5>
                    <h5>Design ID: {this.state.design.id}</h5>
                    {/* {this.state.svg && (
                        parse(this.state.svg)
                    )} */}
                    {/* <img src={`${this.state.design.design_image}`} alt=""/> */}
                    <Preview
                        defs={this.state.defs}
                        brand={this.props.brand}
                        patterns={{
                            'pattern': {
                                name: '',
                                ...this.state.pattern
                            }
                        }}
                        previewLoading={false}
                        loadKitchenView={false}
                        view={this.state.view}
                        ref={this.previewComponent}
                        handleSingleClick={() => this.handleSingleClick()}
                        handleRepeatClick={() => this.handleRepeatClick()}
                        handleKitchenClick={() => this.handleKitchenClick()}
                    />

                </div>
                <div className="info__wrapper">
                    <div className="info row">
                        <div className="info__title">
                            <span>Pattern</span>
                            <span>{this.state.pattern.name}</span>
                        </div>
                        <div className="image">
                            <img src={`${this.state.design.design_image}`} alt="" />
                        </div>
                    </div>
                    <div className="info column">
                        <div className="info__title">
                            <span>Materials</span>
                        </div>
                        <div className={`info__materials ${this.state.pattern.randomize ? 'randomized' : ''}`}>

                            {Object.keys(materials).map((zone: any, idx: any) => {
                                return (
                                    <div className="zone-wrapper" key={idx  }>
                                        <span>{zone}</span>
                                        <div className="materials--wrapper">
                                            {materials[zone].map((material: any, idx2: string) => 
                                                <div className={`info__material ${material.type}`} key={idx2}>
                                                    <img src={`${material.chips.length ? material.chips[0] : material.src}`} alt="" />
                                                    <span>{material.name}</span>
                                                    <span className="percent-material">{material.percent}%</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                                
                            })}

                        </div>
                    </div>
                    <div className="info column">
                        <div className="info__title">
                            <span>Grout</span>
                        </div>
                        <div className="info__grout">
                            {this.state.grout.name === '' || this.state.grout.name === 'No grout' ? (
                                <div>
                                    <img src="/static/images/empty-grout.png" alt="" /> None selected
                                </div>
                            ) : (
                                    <div>
                                        <span className="color" style={style}></span>{this.state.grout.name}
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="info column">
                        <div className="info__title">
                            <span>Specification</span>
                        </div>
                        {this.state.pattern.specification && (
                            <div className="info__spec">
                                <div className="item-spec">
                                    <span>Coverage</span> <span>{JSON.parse(this.state.pattern.specification).coverage} sq. ft.</span>
                                </div>
                                <div className="item-spec">
                                    <span>Row/Sheet</span> <span>{JSON.parse(this.state.pattern.specification).row_sheets}</span>
                                </div>
                                <div className="item-spec">
                                    <span>Thickness</span> <span>{this.state.design.thickness ? this.state.design.thickness : JSON.parse(this.state.pattern.specification).thickness}</span>
                                </div>
                                <div className="item-spec">
                                    <span>Format</span> <span>{JSON.parse(this.state.pattern.specification).format}</span>
                                </div>
                                <div className="item-spec application">
                                    <span>Application</span> <div>{APPLICATION.map((item, idx) => <span key={idx} className={JSON.parse(this.state.pattern.specification).appliacation && JSON.parse(this.state.pattern.specification).appliacation.includes(item) ? 'include' : ''}>{item}</span>)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="step buttons" data-html2canvas-ignore="true">
                        <div className="inputs__wrapper">
                            <div className="input-btn">
                                <div className="button button__quote" onClick={this.quote}>
                                    Request Quote
                                </div>
                            </div>
                            <div className={`input-btn`}>
                                <div className="button button__quote button__quote--blue" onClick={this.sample}>
                                    Get Material Sample
                                </div>
                            </div>
                            <div className="input-btn-groups">
                                <div className="button button__quote" onClick={this.handleDownload}>
                                    Download PDF
                                </div>
                                <div className="button button__quote" onClick={this.handleEdit}>
                                    Edit Design
                                </div>
                            </div>
                            <p>Create your own design at <a href={`http://mosaic-creator.com/${this.props.brand.subdomain}`}>{`mosaic-creator.com/${this.props.brand.subdomain}`}</a></p>
                        </div>
                    </div>
                </div>
                <footer>
                    <p>
                        <b>Locally Crafted to Build a Better Community</b> <br></br>
                        All mosaics are made-to-order by our skilled artisans in Phoenix, Arizona.
                    </p>

                    <p>
                        {this.props.brand.name}
                        <a href={this.props.brand.website}>{this.props.brand.website}</a></p>
                </footer>
                <OrderModal
                    open={this.state.orderModalOpen}
                    close={() => this.setState({ orderModalOpen: false })}
                    sendOrder={(form: any) => this.handleOrder(form)}
                    sample={this.state.sample}
                    brand={this.props.brand}
                />
                <Modal
                    isOpen={this.state.confModal}
                    onRequestClose={() => { this.setState({ confModal: false }) }}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    portalClassName="order-modal-portal"
                    style={{ content: { width: 'calc(490px - 60px)', height: 'fit-content', maxHeight: '65vh', inset: '30% 40px 40px' } }}
                >
                    <div className="close__button--order" onClick={() => this.setState({ confModal: false })}>
                        <Close />
                    </div>
                    <div className="order-modal">
                        <h2>Request Quote</h2>
                        <p>
                            Your request has been received! Thank you for trusting us with your custom mosaic design. Our team will respond with a quote shortly.
                        </p>
                        <p>
                            Our digital tool is approximating the look of your design. Our skilled artisans will create each sheet by hand to ensure that your project is beautiful and cohesive.
</p>
                        <p>
                            If you have any questions, please feel free to send us a message at <a href={`mailto:${this.props.brand.email}`}>{this.props.brand.email}</a>.
                            
                        </p>
                        <div className="form">
                            <button onClick={() => this.setState({ confModal: false })}>Close</button>
                        </div>
                    </div>
                </Modal>

            </div>

        );
    }
}

export default Order;