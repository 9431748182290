
import React from "react";
import Modal from 'react-modal';
import ReactSVG from 'react-svg'
import Select from 'react-select';
import axios from 'axios';
// import * as EventBus from 'eventing-bus';

import Pattern from "../components/pattern";
import Material from "../components/materials";

import Preview from "../components/preview";
import './repeater.scss'
import SaveModal from "../components/saveModal";
import OrderModal from "../components/orderModal";
import ShareModal from "../components/shareModal";

import ReactLoading from "react-loading";
import { Close } from "../components/close";
const EventBus = require('eventing-bus');

export interface ConfiguratorProps {
    brand: any
}

export interface ConfiguratorState {
    materials: any;
    patterns: any;
    selectedOption: any;
    grout: any
    previewTypeLength: any[];
    materialsApi: any;
    groutsApi: any;
    saveModalOpen: boolean;
    shareModalOpen: boolean;
    orderModalOpen: boolean;
    id: string;
    confModal: boolean;
    savedDesign: any;
    defs: any;
    loadingSave: boolean;
    loadingDownload: boolean;
    shareLoading: boolean;
    previewLoading: boolean;
    view: string;
    loadKitchenView: boolean;
    resetModalVisible: boolean;
    blocked: boolean;
    materialsZones: any
    sample: boolean;
    orderSampleSend: boolean;
}

const dot = (color = '#ccc') => {
    return ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            border: color.match('#fff') ? '1px solid #000' : 'none',
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 30,
            width: 30,
            minWidth: 30
        },
    })
};

const groutStyles = {
    // input: (styles: any) => ({ ...styles, ...dot() }),
    option: (styles: any, { data }: { data: any }) => {

        return ({
            ...styles,
            ...dot(data.value.color)
        })
    },
    singleValue: (styles: any, { data }: { data: any }) => ({ ...styles, ...dot(data.value.color) }),
}

class Configurator extends React.Component<ConfiguratorProps, ConfiguratorState> {

    private materialsComponent = React.createRef<Material>()
    private previewComponent = React.createRef<Preview>()

    constructor(props: ConfiguratorProps) {
        super(props)

        this.state = {
            materials: {},
            patterns: {},
            selectedOption: '',
            grout: {},
            previewTypeLength: [0],
            materialsApi: [],
            groutsApi: [],
            saveModalOpen: false,
            shareModalOpen: false,
            orderModalOpen: false,
            id: '',
            confModal: false,
            savedDesign: {},
            defs: [],
            loadingSave: false,
            loadingDownload: false,
            shareLoading: false,
            previewLoading: false,
            view: 'single',
            loadKitchenView: false,
            resetModalVisible: false,
            blocked: false,
            materialsZones: {},
            sample: false,
            orderSampleSend: false

        }

        this.handleGrout = this.handleGrout.bind(this);
        this.addMaterial = this.addMaterial.bind(this);
        this.removeMaterial = this.removeMaterial.bind(this);
        this.goToQuote = this.goToQuote.bind(this);
        this.sample = this.sample.bind(this);
        this.save = this.save.bind(this);
        this.share = this.share.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.handleRepeatClick = this.handleRepeatClick.bind(this);
        this.handleSingleClick = this.handleSingleClick.bind(this);
        this.handleKitchenClick = this.handleKitchenClick.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.closedMaterialModal = this.closedMaterialModal.bind(this);



    }

    componentDidMount() {
        let that = this;

        EventBus.on("loadingStart", () => {

            if (this.previewComponent && this.previewComponent.current) {
                this.previewComponent.current.update();
            }
        });

        EventBus.on("loadingEnd", () => {
        });

        axios.get(`${process.env.REACT_APP_API_URL}/api/grouts`).then((data) => {
            this.setState({
                groutsApi: data.data
            })
            const storedGrout = localStorage.getItem('grouts')
            // console.log(storedGrout);

            if (storedGrout) {
                setTimeout(() => {

                    this.handleGrout(JSON.parse(storedGrout))
                }, 400)
            } else {
                let grouts = document.querySelectorAll('.svg__wrapper #grout');

                grouts.forEach(el => {
                    (el as HTMLElement).style.fill = 'transparent'
                })
                let groutsBackground = document.querySelectorAll('.svg__wrapper');
                if (groutsBackground) {
                    groutsBackground.forEach(el => {
                        (el as HTMLElement).style.fill = '#fff';
                        (el as HTMLElement).style.backgroundColor = 'transparent';
                        // (el as HTMLElement).style.fill = 'transparent'
                    })
                }
            }
        })

        const storedPatterns = localStorage.getItem('patterns')
        if (storedPatterns) {

            this.setState({
                patterns: JSON.parse(storedPatterns),
                materialsZones: JSON.parse(storedPatterns).pattern.materials_zones ? JSON.parse(JSON.parse(storedPatterns).pattern.materials_zones) : {}
            })
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/materials?brand=${this.props.brand.id}`).then((data) => {
            this.setState({
                materialsApi: {
                    'glass': data.data.glass,
                    'natural_stone': data.data.natural_stone,
                    'natural_stone_tumbled': data.data.natural_stone_tumbled,
                    'natural_stone_matte': data.data.natural_stone_matte,
                    'porcelain': data.data.porcelain,
                    'metal': data.data.metal,
                    'uniquely_oceanside': data.data.uniquely_oceanside,
                },
                view: 'single'
            })

            const storedMaterials = localStorage.getItem('materials')
            const storedDefs = localStorage.getItem('defs')

            if (storedMaterials && storedDefs) {
                this.setState({
                    materials: JSON.parse(storedMaterials),
                    defs: JSON.parse(storedDefs)
                })

                setTimeout(() => {

                    Object.keys(JSON.parse(storedMaterials)).forEach((key: any) => {
                        const material = JSON.parse(storedMaterials)[key]
                        const elements: any = document.querySelectorAll(`#zone-${key.replace('material-', '')}`)
                        const materialChips = typeof material.chips === 'string' ? JSON.parse(material.chips) : material.chips;
                        if (elements) {

                            elements.forEach((el: Element) => {
                                const chips = el.querySelectorAll('circle, path, ellipse');
                                let url = material.src
                                chips.forEach((elem: Element) => {
                                    if (materialChips && materialChips.length) {
                                        url = materialChips[Math.floor(Math.random() * materialChips.length)];
                                    }
                                    if (url) {
                                        (elem as HTMLElement).style.fill = `url(#${url.replace('.png', '').replace(`${process.env.REACT_APP_API_URL}/storage/uploads/`, '')})`;
                                    }

                                })
                            })
                        }
                    })
                    if (this.state.patterns['pattern'].randomize && this.materialsComponent.current) {
                        this.materialsComponent.current.radnomizeMaterial();
                    } else if (this.materialsComponent.current) {
                        this.materialsComponent.current.radnomizeMaterialInZone();
                    }

                    const materials = {
                        ...this.state.materials
                    }
                    let sum = 0;

                    for (const key in materials) {
                        if (materials.hasOwnProperty(key)) {
                            const element = materials[key];
                            sum += parseInt(element.percent)
                        }
                    }

                    this.setState({
                        blocked: sum !== 100 && this.state.patterns['pattern'].randomize === 1
                    })
                }, 1000)
            }

        })

    }

    public parseOldHouseBlendMaterials(materials: any, randomize: boolean = false) {
        let returnMaterials = {}

        for (const key in materials) {
            if (materials.hasOwnProperty(key)) {

                const element = materials[key];
                if (key.match('material-[0-9]{1,2}_')) {
                    returnMaterials = {
                        ...returnMaterials,
                        [key]: element
                    };
                } else {
                    element.percent = randomize ? element.percent : 100;
                    element.zone = randomize ? "1" : element.zone || key.replace('material-', '').replace('_[0-9]{1,2}', '');
                    returnMaterials = {
                        ...returnMaterials,
                        [`material-${element.zone}_${key.replace('material-', '')}`]: element
                    }
                }
            }
        }

        return returnMaterials
    }

    handleBlend(item: any, key: string, id: string) {
        this.setState({
            defs: [],
            materials: {},
        })


        if (item.blend) {

            this.setState({
                patterns: {
                    'pattern': {
                        ...this.state.patterns['pattern'],
                    }
                },
                defs: JSON.parse(item.defs),
                materials: this.parseOldHouseBlendMaterials(JSON.parse(item.materials), this.state.patterns['pattern'].randomize),
                blocked: false
            })




            if (this.state.patterns['pattern'].randomize && this.materialsComponent.current) {
                this.materialsComponent.current.radnomizeMaterial();
            } else if (this.materialsComponent.current) {
                this.materialsComponent.current.radnomizeMaterialInZone();
            }

            if (JSON.parse(item.grout).name === "") {
                item.grout = {
                    name: "",
                    color: "transparent",
                    id: 0
                }
            } else {
                item.grout = JSON.parse(item.grout)
            }
            this.handleGrout({
                value: item.grout,
                label: item.grout.name
            })
            setTimeout(() => {
                localStorage.setItem('materials', JSON.stringify(this.state.materials))
                localStorage.setItem('patterns', JSON.stringify(this.state.patterns))
                localStorage.setItem('defs', JSON.stringify(this.state.defs))
            }, 200)
        }
    }

    handleMaterial(item: any, key: string, id: string, prev: any) {

        let materialType = item.type

        if (materialType === 'oval' || materialType === 'round' || materialType === 'diamond' || materialType === 'leaf') {
            materialType = materialType.charAt(0).toUpperCase() + materialType.slice(1)
        }

        let defs = [...this.state.defs, {
            chips: JSON.parse(item.chips)[materialType],
            src: item.src,
        }]

        let percent: any = 0;
        console.log('this.state.materials',this.state.materials);
        console.log('this.state.materialsAPI',this.state.materialsApi);
        
        const materials = {
            ...this.state.materials,
            [`${id}`]: {
                src: `${item.src}`,
                name: item.name,
                category: item.category,
                chips: JSON.parse(item.chips)[materialType] || [],
                total: item.total || 0,
                loaded: true,
                percent: 0,
                type: item.type,
                key: id,
                zone: id.replace(new RegExp("_[0-9]{1,2}", "g"), '').replace("material-", '')
            }
        }
        console.log(materials);
        
        Object.keys(materials).filter((matKey) => materials[matKey] && materials[matKey].name).forEach((metKey) => {
            if (id.replace(new RegExp("_[0-9]{1,2}", "g"), '') !== metKey.replace(new RegExp("_[0-9]{1,2}", "g"), '')) {
                return false;
            }
            const materialsInZone = Object.keys(materials).filter((key) => materials[key] && materials[key].name && key.match(metKey.replace(new RegExp("_[0-9]{1,2}", "g"), '')));

            let sum = 0;
            materials[metKey].percent = 100 / materialsInZone.length

            if (materialsInZone.length === 3) {
                materialsInZone.forEach((key) => {
                    if (!prev || sum !== 100) {
                        materials[key].percent = 33;
                    }
                });
                materials[metKey].percent = 34
            }

            if (materialsInZone.length === 6) {
                materialsInZone.forEach((key, index) => {
                    if (!prev || sum !== 100) {
                        materials[key].percent = 16;
                    }
                });
                materials[metKey].percent = 20
            }
            if (materialsInZone.length === 7) {
                materialsInZone.forEach((key, index) => {
                    if (!prev || sum !== 100) {
                        materials[key].percent = 14;
                    }
                });
                materials[metKey].percent = 16
            }
            if (materialsInZone.length === 8) {
                materialsInZone.forEach((key, index) => {
                    if (!prev || sum !== 100) {
                        materials[key].percent = 12;
                    }
                });
                materials[metKey].percent = 16
            }
            if (materialsInZone.length === 9) {
                materialsInZone.forEach((key, index) => {
                    if (!prev || sum !== 100) {
                        materials[key].percent = 11;
                    }
                });
                materials[metKey].percent = 12
            }
        })

        this.setState({
            savedDesign: {},
            materials,
            defs: defs,
            blocked: Object.keys(materials).length !== Object.keys(materials).filter((key) => materials[key].name).length
        })

        if (this.state.view === 'kitchen') {
            this.setState({
                view: 'single'
            })
        }

        setTimeout(() => {
            if (this.state.view === 'kitchen' && this.state.patterns['pattern'].randomize) {
                this.handleRoomClick()
            }
            localStorage.setItem('materials', JSON.stringify(this.state.materials))
            localStorage.setItem('patterns', JSON.stringify(this.state.patterns))
            localStorage.setItem('defs', JSON.stringify(this.state.defs))
        }, 400)
    }

    public handlePattern(pattern: any) {
        delete this.state.materialsApi.house_blends;

        const materialsLength = pattern.randomize ? 1 : pattern.zones;
        let materials = {};

        for (let index = 1; index <= materialsLength; index++) {
            Object.assign(materials, {
                [`material-${index}_1`]: {
                    type: JSON.parse(pattern.materials_zones)[`zone-${index}`],
                    percent: 100
                }
            })
        };

        this.setState({
            materials,
            grout: {},
            selectedOption: '',
            defs: [],
            blocked: true,
            view: 'single',
            savedDesign: {},
            materialsApi: {
                ...this.state.materialsApi
            },
            patterns: {
                [`pattern`]: {
                    ...pattern,
                    zones: pattern.randomize ? 1 : pattern.zones,
                    randomize: pattern.zones === 1 || pattern.zones === 0
                }
            },

        })

        if (this.materialsComponent.current) {
            this.materialsComponent.current.removePercent();
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/materials/${pattern.id}`).then((response) => {

            this.setState({
                materialsApi: {
                    'house_blends': response.data,
                    ...this.state.materialsApi
                }
            })
        })

        setTimeout(() => {

            let grouts = document.querySelectorAll('.svg__wrapper #grout');

            grouts.forEach((el) => {
                (el as HTMLElement).style.fill = 'transparent';
                (el as HTMLElement).classList.remove('none');
                if (this.state.view === 'single') {
                    (el as HTMLElement).classList.add('none');
                    (el as HTMLElement).style.fill = 'transparent';
                }
            })

            let groutsBackground = document.querySelectorAll('.svg__wrapper');
            if (groutsBackground) {
                groutsBackground.forEach((el) => {
                    (el as HTMLElement).style.fill = 'transparent';
                    (el as HTMLElement).style.backgroundColor = '#fff';
                })
            }

            document.querySelectorAll('svg circle, svg rect, svg ellipse, svg path, svg polygon').forEach((el) => {
                (el as HTMLElement).style.fill = ''
            })
            localStorage.setItem('patterns', '')
            localStorage.setItem('materials', '')
            localStorage.setItem('grouts', '')
            localStorage.setItem('defs', '')

            // if (this.state.patterns['pattern'].randomize && this.materialsComponent.current) {
            //     this.materialsComponent.current.openModal();
            // }
            this.forceUpdate();
        }, 200)
    }

    public handleGrout(e: any) {
        if (!e.value) {
            e = {
                value: e,
                label: e.name
            }
        }
        const color = e.value.color;
        if (e.value.id === 0) {
            this.setState({
                grout: {},
                selectedOption: null,
                savedDesign: {}
            });
        } else {
            this.setState({
                grout: e.value,
                selectedOption: e,
                savedDesign: {}
            });
        }


        let grouts = document.querySelectorAll('.svg__wrapper #grout');
        console.log(grouts);

        grouts.forEach(el => {
            console.log(color);

            (el as HTMLElement).style.fill = color;
            (el as HTMLElement).classList.remove('none');
            if (this.state.view === 'single' && color === '') {
                (el as HTMLElement).classList.add('none');
                (el as HTMLElement).style.fill = 'transparent';
            }
        })

        let repeatBackground = document.querySelector('.svg__wrapper');
        if (repeatBackground) {
            (repeatBackground as HTMLElement).style.backgroundColor = color;
        }

        if (this.state.view === 'kitchen') {
            this.setState({
                view: 'single'
            })
        }

        localStorage.setItem('grouts', JSON.stringify(e))
    }

    public addMaterial(index?: any) {
        const materialsInZone = Object.keys(this.state.materials).filter((key) => key.match(`material-${index}`));
        let lastIndex: any = materialsInZone.map((key) => parseInt(key.replace('material-', '').replace(new RegExp("[0-9]{1,2}_", "g"), ''))).sort((a, b) => a - b)

        lastIndex = parseInt(lastIndex[lastIndex.length - 1]);

        if (index) {
            this.setState({
                materials: {
                    ...this.state.materials,
                    [`material-${index}_${lastIndex + 1 || 1}`]: {
                        type: this.state.materials[materialsInZone[0]].type
                    }
                }
            })
        } else {
            this.setState({
                materials: {
                    ...this.state.materials,
                    [`material-${index}_${lastIndex + 1 || 1}`]: {
                        type: this.state.materials[`material-1_1`].type,
                        percent: 100 / (Object.keys(this.state.materials).length + 1)
                    }
                }
            })
        }

    }

    public removeMaterial(material: any, empty: boolean = false) {
        const a = this.state.materials;

        const materialsInZone = Object.keys(this.state.materials).filter((key) => {
            console.log(this.state.materials[key]);
            console.log(material);
            
            return this.state.materials[key].zone == material.zone
        }).length
            ? Object.keys(this.state.materials).filter((key) => this.state.materials[key] ? this.state.materials[key].zone == material.zone : true)
            : Object.keys(this.state.materials)

        const materialFound = materialsInZone.filter((key) => this.state.materials[key].name == material.name)[0];
        const removedMaterialFromZone = materialFound.replace(new RegExp("_[0-9]{1,2}", "g"), '');
        let materials: any = this.state.materials;

        if (materialsInZone.length === 1) {
            materials[materialFound] = {
                zone: materials[materialFound].zone,
                type: materials[materialFound].type,
                percent: 0
            };

            this.setState({
                materials,
                blocked: Object.keys(materials).length !== Object.keys(materials).filter((key: any) => materials[key].name).length
            })
            const zone = document.querySelector(`#zone-${materialFound.replace('material-', '').replace(new RegExp("_[0-9]{1,2}", "g"), '')}`);

            if (zone) {
                zone.querySelectorAll(`svg circle, svg rect, svg ellipse, svg path, svg polygon`).forEach((el) => {
                    (el as HTMLElement).style.fill = ''
                })
            }

            localStorage.setItem('materials', JSON.stringify(this.state.materials))
            localStorage.setItem('patterns', JSON.stringify(this.state.patterns))
            return false;
        }
        if (empty) {
            delete materials[materialFound];

            this.setState({
                materials,
                blocked: Object.keys(materials).length !== Object.keys(materials).filter((key: any) => materials[key].name).length
            })
            const zone = document.querySelector(`#zone-${materialFound.replace('material-', '')}`);
            if (zone) {
                zone.querySelectorAll(`svg circle, svg rect, svg ellipse, svg path, svg polygon`).forEach((el) => {
                    (el as HTMLElement).style.fill = ''
                })
            }
            return false;
        }

        Object.keys(materials).filter((key) => key !== materialFound).forEach((key, index) => {
            delete materials[materialFound];

            if (removedMaterialFromZone !== key.replace(new RegExp("_[0-9]{1,2}", "g"), '')) {
                return false;
            }

            const zone = materials[key].zone;

            let percent = 100 / (Object.keys(materials).filter((key) => materials[key].zone == zone).length)

            materials = {
                ...materials,
                [key]: {
                    ...materials[key],
                    percent
                }
            }
        });
        const leftMaterials = materialsInZone.filter((key) => key !== materialFound);

        if (materialsInZone.length === 4) {
            leftMaterials.forEach(function (key, index) {
                if (index === 2) {
                    (materials as any)[key].percent = 34;
                } else {
                    (materials as any)[key].percent = 33;
                }
            });
        }

        if (materialsInZone.length === 7) {
            leftMaterials.forEach(function (key, index) {
                if (index === 5) {
                    (materials as any)[key].percent = 20;
                } else {
                    (materials as any)[key].percent = 16;
                }
            });
        }
        if (materialsInZone.length === 8) {
            leftMaterials.forEach(function (key, index) {
                if (index === 6) {
                    (materials as any)[key].percent = 16;
                } else {
                    (materials as any)[key].percent = 14;
                }
            });
        }
        if (materialsInZone.length === 9) {
            leftMaterials.forEach(function (key, index) {
                if (index === 7) {
                    (materials as any)[key].percent = 16;
                } else {
                    (materials as any)[key].percent = 12;
                }
            });
        }
        if (materialsInZone.length === 10) {
            leftMaterials.forEach(function (key, index) {
                if (index === 8) {
                    (materials as any)[key].percent = 12;
                } else {
                    (materials as any)[key].percent = 11;
                }
            });
        }


        this.setState({
            materials,
            savedDesign: {},
            blocked: Object.keys(materials).length !== Object.keys(materials).filter((key: any) => materials[key].name).length
        })

        if (this.state.view === 'kitchen') {
            this.setState({
                view: 'single'
            })
        }
        setTimeout(() => {

            if (this.state.patterns['pattern'].zones === 0) {
                document.querySelectorAll('svg circle, svg rect,svg ellipse, svg path, svg polygon').forEach((el) => {
                    (el as HTMLElement).style.fill = ''
                })
            }
            localStorage.setItem('materials', JSON.stringify(this.state.materials))
            localStorage.setItem('patterns', JSON.stringify(this.state.patterns))
        }, 200)

        setTimeout(() => {
            if (this.state.view === 'kitchen' && this.state.patterns['pattern'].randomize) {
                this.handleRoomClick()
            }
        }, 500)
    }

    public goToQuote() {
        this.setState({
            sample: false,
            orderModalOpen: true
        })

    }
    public sample() {
        this.setState({
            sample: true,
            orderModalOpen: true
        })

    }
    public downloadURI(uri: any, name?: any) {
        var link = document.createElement("a");
        if (name) {
            link.download = name
        }
        link.target = '_blank';
        link.href = uri;
        // link.click();

        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            (document as any).location = link.href;
            // window event not working here
        } else {
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            link.dispatchEvent(evt);
            (window.URL || (window as any).webkitURL).revokeObjectURL(link.href);
        }

    }

    public dataURLtoBlob(dataurl: string) {
        let arr = dataurl.split(',');
        if (arr && arr[0] && arr[0].match(/:(.*?);/)) {
            let mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1];
            let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }

    public toDataURL(url: any, callback: any) {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/materials?brand=${this.props.brand.id}`, {
            materials: JSON.stringify(url)
        }).then((res) => {
            callback(res.data)
        })

    }


    public share() {
        this.setState({
            shareLoading: true
        })
        if (this.state.savedDesign.id) {
            this.setState({
                shareModalOpen: true,
                shareLoading: false
            })
        } else {
            this.save(null, false).then((res) => {

                if (res) {
                    this.setState({
                        shareModalOpen: true,
                        shareLoading: false
                    })
                } else {
                    this.setState({
                        shareLoading: false
                    })
                }
            })
        }
    }

    public save(e?: any, modalShow = true) {

        if (!this.validate()) {
            alert('You need to select materials')
            return Promise.resolve(false);
        }

        if (this.state.savedDesign.id) {
            // this.setState({
            //     saveModalOpen: true,
            // })
            return Promise.resolve(true)
        }
        this.setState({
            loadingSave: modalShow,
            view: 'single'
        })

        const defs = document.querySelector('svg.materials defs');

        const can = (document.querySelector('.injected-svg'));
        const images = document.querySelectorAll('image')

        if (defs && can) {
            can.appendChild(defs);
        }
        const promise: any = [];
        const materials: any = [];
        if (images) {
            images.forEach((img, index) => {
                let src = img.getAttribute('xlink:href');
                let parent = img.parentElement;
                let id = ''
                if (parent) {
                    id = parent.getAttribute('id') || '';
                }
                materials.push({
                    id,
                    src
                })
            })

            promise.push(new Promise((resolve) => this.toDataURL(materials, (materialsReturn: any) => {


                materialsReturn.forEach((material: any) => {
                    const imageMaterial = document.getElementById(`${material.id}`);


                    if (imageMaterial) {
                        (imageMaterial.querySelector('image') as any).setAttribute('xlink:href', material.base64);

                    }
                })
                return resolve(true);
            })))
        }
        // return Promise.all(promise).then(() =>{
        //     return resolve();
        // });
        return Promise.all(promise).then(() => {

            const s = document.querySelector('.preview .injected-svg');
            let grouts = document.querySelectorAll('.svg__wrapper #grout');
            grouts.forEach((el) => {
                (el as HTMLElement).style.fill = !this.state.grout.color ? '#fff' : this.state.grout.color;
            })

            if (s) {
                const svgHtml = s.outerHTML;
                var svg = s
                var svgData = new XMLSerializer().serializeToString(svg);

                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");

                var img = document.createElement("img");
                var svgSize = svg.getBoundingClientRect();


                canvas.width = (586 * 2 + 50);
                canvas.height = (586 * 2 + 30);
                img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));


                let that = this;
                return new Promise((resolve) => {
                    // (ctx as any).drawImage(img, 0, 0);
                    // var myImage = canvas.toDataURL("image/png");
                    // // this.downloadURI(myImage, 'design')
                    // let blob = that.dataURLtoBlob(myImage);
                    axios.post('https://mosaic-creator.com/convertSvg', { svg: encodeURIComponent(svgData) }, {
                        responseType: "blob"
                    }).then((data) => {

                        const blob = data.data;
                        grouts.forEach((el) => {
                            (el as HTMLElement).style.fill = !this.state.grout.color ? 'transparent' : this.state.grout.color;
                        })
                        var fd = new FormData();
                        if (blob) {
                            let materials = that.state.materials;
                            const brandId = this.props.brand.id || null;

                            const defsMaterials = document.querySelector('svg.materials');
                            const defs = document.querySelector('.injected-svg defs');
                            //console.log(defs);

                            if (defsMaterials && defs) {

                                defsMaterials.appendChild(defs)
                            }
                            const isPorcelain = Object.keys(materials).filter((key) => materials[key].category === 'porcelain').length
                            // console.log(Object.keys(materials).filter((key) => materials[key].category === 'porcelain').length);

                            if (svg)
                                fd.append('design_image', blob)
                            fd.append('svg', 'svg');
                            fd.append('defs', JSON.stringify(that.state.defs));
                            fd.append('materials', JSON.stringify(materials));
                            fd.append('thickess', !!isPorcelain ? '3/16"' : '3/8"');
                            fd.append('grout', !Object.keys(that.state.grout).length ? '{"id":0,"name":"","color":"#ffffff"}' : JSON.stringify(that.state.grout));
                            fd.append('patternId', that.state.patterns['pattern'].id);
                            fd.append('brand_id', brandId);

                            return axios(({
                                method: 'post',
                                url: `${process.env.REACT_APP_API_URL}/api/save-design`,
                                data: fd,
                                config: {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            } as any)).then((data: any) => {

                                that.setState({
                                    id: data.data.id,
                                    // saveModalOpen: modalShow,
                                    savedDesign: data.data,
                                    loadingSave: false
                                })


                                return resolve(true)
                            })
                                .catch((e: any) => {
                                    // console.log(e);
                                    this.setState({
                                        loadingSave: false
                                    })
                                    alert('Something went wrong')
                                });
                        }
                    });
                });


            } else {
                this.setState({
                    loadingSave: false
                })
                return Promise.resolve(false)
            }
        })

    }

    public validate() {
        let groutsLength = Object.keys(this.state.grout).length;
        let matrialsLength = Object.keys(this.state.materials).length;

        return matrialsLength;
    }

    public getPdf() {

        if (!this.validate()) {
            alert('You need to select materials')
            return false;
        }

        this.setState({
            loadingDownload: true
        })
        if (this.state.savedDesign.id) {
            this.downloadURI(this.state.savedDesign.pdf_url, `${this.state.savedDesign.id}.pdf`)
            this.setState({
                loadingDownload: false
            })
        } else {
            this.save(null, false).then((url) => {
                setTimeout(() => {
                    this.downloadURI(this.state.savedDesign.pdf_url, `${this.state.savedDesign.id}.pdf`)
                    this.setState({
                        loadingDownload: false
                    })
                }, 6000)
            })
        }
    }

    public getOptions() {
        let api = this.state.groutsApi.map((grout: any, idx: number) => { return { value: grout, label: grout.name } })
        return [{
            value: {
                name: 'No grout',
                color: ''
            }, label: 'No grout'
        }, ...api];
    }

    public changePercent(material: any, materialKey: any) {

        this.setState({
            materials: {
                ...this.state.materials,
                [materialKey]: { ...material }
            }
        })

        const materials = {
            ...this.state.materials,
            [materialKey]: { ...material }
        }
        let sum = 0;

        for (const key in materials) {
            if (materials.hasOwnProperty(key)) {
                const element = materials[key];
                sum += parseInt(element.percent)
            }
        }

        this.setState({
            blocked: Object.keys(materials).length !== Object.keys(materials).filter((key) => materials[key].name).length

        })
        localStorage.setItem('materials', JSON.stringify(this.state.materials))

    }

    public handleRepeatClick() {
        this.setState({ view: 'repeat' })
        if (this.previewComponent.current) {
            const storedGrout = localStorage.getItem('grouts')
            if (storedGrout) {
                this.handleGrout(JSON.parse(storedGrout))
            }
            this.previewComponent.current.update();
        }

    }

    public handleSingleClick() {
        this.setState({ view: 'single' })

        if (this.previewComponent.current) {
            this.previewComponent.current.update();
        }
    }

    public handleKitchenClick() {
        this.setState({ view: 'kitchen' })
        if (this.previewComponent.current) {
            this.previewComponent.current.update();
        }
        this.handleRoomClick()
    }

    public handleRoomClick() {

    }

    public handleOrder(form: any) {
        this.save(null, false).then((data) => {
            console.log(form);

            this.setState({
                orderSampleSend: form.address
            });
            form = { ...form, thankYouMessage: this.getThankYouMessage() }
            const id = this.state.savedDesign.id;
            const brandId = this.props.brand.id || null;
            axios.post(`${process.env.REACT_APP_API_URL}/api/save-order`, {
                id,
                form,
                brand_id: brandId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {

                this.setState({
                    orderModalOpen: false,
                    confModal: true,
                    loadingSave: false
                })

            }).catch((e) => console.log(e));
        })
    }

    public randomizedMaterial() {
        let materials: any = this.state.materials;
        const tile = document.querySelector('.svg__wrapper > div')
        let chips: any;
        if (tile) {
            chips = tile.querySelectorAll('g circle:not(#grout), g rect:not(#grout), g path:not(#grout), g ellipse:not(#grout), g polygon:not(#grout)');
        }
        if (materials) {
            Object.keys(materials).forEach((key) => {
                const elements: any = document.querySelectorAll(`.singleSVG .${key}`)
                materials[key].total = elements.length
            });
        }

        this.setState({
            materials
        })

        if (this.state.view === 'kitchen' && this.state.patterns['pattern'].randomize) {
            this.handleRoomClick()
        }
    }

    public handleReset() {
        this.setState({
            patterns: {},
            materials: {},
            grout: {},
            selectedOption: {
                value: '',
                label: 'Select...'
            },
            resetModalVisible: false
        })

        localStorage.setItem('patterns', '')
        localStorage.setItem('materials', '')
        localStorage.setItem('grouts', '')
        localStorage.setItem('defs', '')
    }

    public closedMaterialModal() {
        let materials: any = {};
        console.log('this.state.materials', this.state.materials);

        if (this.state.materials) {
            Object.keys(this.state.materials).forEach((key) => {
                const zone = key.replace('material-', '').replace(/_\d*/, '');
                const material = this.state.materials[key];
                const materialsFromZone = Object.keys(this.state.materials).filter((key) => this.state.materials[key].zone == zone)

                if (material && material.name) {


                    materials = {
                        ...materials,
                        [key]: material
                    }
                } else if (materialsFromZone.filter((key) => !this.state.materials[key].name).length === materialsFromZone.length) {
                    if (this.state.patterns['pattern'].zones === 1) {
                        materials = {
                            [key]: {
                                ...material,
                                percent: material.percent === 0 ? 100 : material.percent
                            }
                        }
                    } else {
                        materials = {
                            ...materials,
                            [key]: {
                                ...material,
                                percent: material.percent === 0 ? 100 : material.percent
                            }
                        }
                    }
                }

            })
            console.log('materials', materials);
            // Object.keys(this.state.materials)
            this.setState({
                materials
            })
            localStorage.setItem('materials', JSON.stringify(materials))
        }
    }
    private getThankYouMessage() {
        if (this.state.sample) {
            return "Our sample team will send you all of the materials included in your design promptly!"
        }

        if (!this.state.sample && this.state.orderSampleSend) {
            return "Our sample team will respond with a quote promptly and send you a sample of all materials you selected!"
        }

        if (!this.state.sample) {
            return "Our professional team will respond with a quote shortly!"
        }
    }

    render() {
        return (
            <div>
                <div className="title">
                    <div className="title-header"></div>
                    <div className="title-header-secondary">
                        <img className="icon" src="/static/images/flag.png" alt="" />Made in the USA
                    <img className="icon-delivery" src="/static/images/delivery_200_transparent.gif" alt="" />Ships in 14 days

                    </div>
                </div>
                <div className="app">
                    <div className="configurator">
                        <div className={`steps ${this.state.patterns['pattern'] ? '' : 'no-pattern'}`}>
                            <Pattern brand={this.props.brand.id} patterns={this.state.patterns} handlePattern={(item: any) => this.handlePattern(item)} />
                            <Material
                                handleMaterials={(item: any, key: any, id: any, prev: any) => this.handleMaterial(item, key, id, prev)}
                                meterialAdded={(index: any) => this.addMaterial(index)}
                                removedMaterial={(material: any, empty?: any) => this.removeMaterial(material, empty)}
                                changePercent={(percent: any, id: number) => this.changePercent(percent, id)}
                                patterns={this.state.patterns}
                                materials={this.state.materials}
                                materialsApi={this.state.materialsApi}
                                handleBlend={(item: any, key: any, id: any) => this.handleBlend(item, key, id)}
                                randomizedMaterial={() => this.randomizedMaterial()}
                                ref={this.materialsComponent}
                                locked={this.state.view === 'kitchen'}
                                closedModal={this.closedMaterialModal}
                            />
                            <div className={`step grout ${this.state.view === 'kitchen' ? '' : ''}`}>
                                <div className="step-name">
                                    <div className="index">03</div>
                                    <div className="name">Grout</div>
                                </div>
                                <div className="input">
                                    <Select
                                        value={this.state.selectedOption}
                                        onChange={this.handleGrout}
                                        options={this.getOptions()}
                                        styles={groutStyles}
                                        className="select-grout"
                                        classNamePrefix="select-grout"
                                    />
                                </div>
                            </div>
                            <div className="step buttons">
                                <div className="step-name">
                                    <div className="index">04</div>
                                    <div className="name">Get Your Mosaic</div>
                                </div>
                                <div className="inputs__wrapper">
                                    <div className={`input-btn ${this.state.blocked ? 'blocked' : ''}`}>
                                        <div className="button button__quote" onClick={this.goToQuote}>
                                            Request Quote
                                        </div>
                                    </div>
                                    <div className={`input-btn ${this.state.blocked ? 'blocked' : ''}`}>
                                        <div className="button button__quote button__quote--blue" onClick={this.sample}>
                                            Get Material Sample
                                        </div>
                                    </div>
                                    <div className={`input-btn-groups ${this.state.blocked ? 'blocked' : ''}`}>
                                        <div className="button button__quote" onClick={this.getPdf}>
                                            {this.state.loadingDownload ? (
                                                <ReactLoading type="spin" color="#333" width="30px" height="30px" />
                                            ) : (
                                                    <span><ReactSVG beforeInjection={svg => svg.classList.remove('injected-svg')} className="svg" src="/static/images/download.svg" /> Download PDF</span>
                                                )}
                                        </div>
                                        <div className="button button__quote" onClick={this.share}>
                                            {this.state.shareLoading ? (
                                                <ReactLoading type="spin" color="#333" width="30px" height="30px" />
                                            ) : (
                                                    <span><ReactSVG beforeInjection={svg => svg.classList.remove('injected-svg')} className="svg" src="/static/images/share.svg" /> Share Design</span>
                                                )}
                                        </div>
                                    </div>
                                    {Object.keys(this.state.materials).length > 0 && (
                                        <p>Ready to start a new design? <a href="" onClick={(e) => { e.preventDefault(); this.setState({ resetModalVisible: true }) }}>Clear All Options</a></p>
                                    )}
                                </div>
                            </div>
                            <p className="step">{this.props.brand.copy ? <div dangerouslySetInnerHTML={{ __html: this.props.brand.copy }} /> : `Is your imagination bigger than this tool’s capabilities? We love a good challenge! We are experts at taking your vision and turning it into a tangible, custom product. Tell us about your big idea at ${this.props.brand.email}. Finished product manufactured in Phoenix AZ and ships in 14 calendar days.`}</p>
                            <a href="http://www.yourimagination.us/" className="imagination-logo" target="_blank"><img src="/static/images/yourimagination.png" alt="" /></a>
                        </div>
                    </div>
                    <Preview
                        onlySingle={Object.keys(this.state.materials).length === 0}
                        defs={this.state.defs}
                        brand={this.props.brand}
                        patterns={this.state.patterns}
                        previewLoading={this.state.previewLoading}
                        loadKitchenView={this.state.loadKitchenView}
                        view={this.state.view}
                        ref={this.previewComponent}
                        handleSingleClick={() => this.handleSingleClick()}
                        handleRepeatClick={() => this.handleRepeatClick()}
                        handleKitchenClick={() => this.handleKitchenClick()}
                    />

                </div>
                <canvas id="canvas" width="1200" height="1200" style={{
                    display: 'none'
                } as React.CSSProperties
                }></canvas>
                <SaveModal open={this.state.saveModalOpen} close={() => this.setState({ saveModalOpen: false })} id={this.state.id} design={this.state.savedDesign} brand={this.props.brand} />
                <ShareModal open={this.state.shareModalOpen} close={() => this.setState({ shareModalOpen: false })} id={this.state.id} design={this.state.savedDesign} brand={this.props.brand} />
                <OrderModal
                    open={this.state.orderModalOpen}
                    close={() => this.setState({ orderModalOpen: false })}
                    sendOrder={(form: any) => this.handleOrder(form)}
                    sample={this.state.sample}
                    brand={this.props.brand}
                />
                <Modal
                    isOpen={this.state.confModal}
                    onRequestClose={() => { this.setState({ confModal: false }) }}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    portalClassName="order-modal-portal"
                    style={{ content: { width: 'calc(490px - 60px)', height: 'fit-content', maxHeight: '65vh', inset: '30% 40px 40px' } }}
                >
                    <div className="close__button--order" onClick={() => this.setState({ confModal: false })}>
                        <Close />
                    </div>
                    <div className="order-modal">
                        <h2>{this.state.sample ? 'Get Material Sample' : 'Request Quote'}</h2>
                        <p>
                            Thank you! Your request has been received. We appreciate you trusting us with your custom mosaic design. {this.getThankYouMessage()}
                        </p>
                        <p>
                            While our digital tool is very good at approximating the look of your design, our skilled artisans are even better and will create each sheet by hand to ensure that your project is beautiful and cohesive.
                        </p>
                        <p>
                            If you have any questions, please feel free to send us a message at <a href={`mailto:${this.props.brand.email}`}>{this.props.brand.email}</a>. We will get back to you as soon as possible.

                        </p>
                        <div className="form">
                            <button onClick={() => this.setState({ confModal: false })}>Close</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.resetModalVisible}
                    onRequestClose={() => { this.setState({ resetModalVisible: false }) }}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    portalClassName="order-modal-portal reset"
                    style={{ content: { width: '450px', height: '240px' } }}
                >
                    <div className="order-modal">
                        <div className="close__button close__button--order" onClick={() => this.setState({ resetModalVisible: false })}>
                            <Close />
                        </div>
                        <h2>Are you sure?</h2>
                        <p>You will lose all of your settings. This cannot be undone. </p>
                        <div className="form">
                            <div onClick={this.handleReset}><span>Yes!</span>Clear all selections & start a new design</div>
                        </div>
                        <p className="last"><a href="#" onClick={() => this.setState({ resetModalVisible: false })}>Cancel</a>, I’m not done with this design yet.</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Configurator;