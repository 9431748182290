import React from "react";
import ReactSVG from "react-svg";
import ReactLoading from "react-loading";
import axios from 'axios';
import fs from 'fs';
import { Kitchen } from '../views/room-preview-kitchen';

export interface PreviewProps {
    defs: any;
    brand: any;
    patterns: any;
    previewLoading: boolean;
    loadKitchenView: boolean;
    view: string;
    handleSingleClick: any;
    handleRepeatClick: any;
    handleKitchenClick: any;
    onlySingle?: any;
}

export interface PreviewState {
    loading: boolean;
}

const transformRot90 = {
    transform: 'rotate(90deg)',
} as React.CSSProperties


class Preview extends React.Component<PreviewProps, PreviewState> {
    constructor(props: PreviewProps) {
        super(props);
        this.state = {
            loading: false
        };

        this.handleRoomClick = this.handleRoomClick.bind(this)
    }

    public componentDidMount() {
        setTimeout(() => {
            this.props.handleSingleClick();
        }, 2000);
    }

    public toDataURL(url: any, callback: any) {

        return axios.post(`${process.env.REACT_APP_API_URL}/api/materials?brand=${this.props.brand.id}`, {
            materials: JSON.stringify(url)
        }).then((res) => {
            callback(res.data)
        })

    }
     
    public update() {
        setTimeout(() => {
            this.forceUpdate()
            this.setState({
                loading: false
            })
        }, 400)
    }

    public handleRoomClick() {
        // this.setState({
        //     loading: true
        // })
        this.props.handleKitchenClick();
    }

    public handleSingleClick() {
        // this.setState({
        //     loading: true
        // })
        this.props.handleSingleClick();
    }
    public handleRepeatClick() {
        // this.setState({
        //     loading: true
        // })
        this.props.handleRepeatClick();
    }


    public generateDefs(url: string) {
        const tile = document.querySelector('.repeatSVG g[id^="board-"] svg');
        const tileWidth = tile && tile.getAttribute('width') ? tile.getAttribute('width') : '1200';
        const tileWidthProportions = tileWidth ? parseInt(tileWidth) / 1200 : 0;

        let view = '';
        switch (this.props.view) {
            case 'single':
                view = 'singleSVG'
                break;
            case 'repeat':
                view = 'repeatSVG'
                break;
            default:
                view = 'repeatSVG'
                break;
        }
        
        const el = document.querySelector(`.${view} ${view === 'singleSVG' ? '' : 'g[id^="board-"]'} [style*="#${url.replace('.png', '')}"]`);
        console.log(el);
        
        if (el) {
        }
        
        const width = el ? el.getAttribute('width') : 0;
        const height = el ? el.getAttribute('height') : 0;
        
        if (this.props.view === 'single') {
            
            if (url.match('material-') && url.match('_Diamond')) {
                return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
            }
            
            if (url.match('Oval')) {
                return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
            }
            if (url.match('Leaf')) {
                return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
            }
            if (!url.match('material_Round') && url.match(/material_[A-Z0-9]/)) {
                return <image x="0" y="0" width={`${width}`} height={`${height}`} xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1.25) {
                return <image x="0%" y="0%" width="65" height="65"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1) {
                return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions > 0.95) {                
                return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
            }
            if (tileWidthProportions > 0.78) {
                return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image> 
            }
        } else if (this.props.view === 'repeat') {
            
            if (url.match('material-') && url.match('_Diamond')) {
                return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
            }
            if (url.match('Oval')) {
                return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
            }
            if (url.match('Leaf')) {
                return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
            }
            if (!url.match('material_Round') && url.match(/material_[A-Z0-9]/)) {
                return <image x="0" y="0" width={`${width}`} height={`${height}`} xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1.25) {
                return <image x="0%" y="0%" width="61" height="61"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1) {
                return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions > 0.95) {
                return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
            }
            if (tileWidthProportions > 0.78) {
                return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image>
            }
        } else if (this.props.view === 'kitchen') {
            
            if (url.match('material-') && url.match('_Diamond')) {
                return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
            }
            if (url.match('Oval')) {
                return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
            }
            if (url.match('Leaf')) {
                return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
            }
            if (!url.match('material_Round') && url.match(/material_[A-Z0-9]/)) {
                return <image x="0" y="0" width={`${width}`} height={`${height}`} xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1.25) {
                return <image x="0%" y="0%" width="61" height="61"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions >= 1) {
                return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
            }
            if (tileWidthProportions > 0.95) {
                return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
            }
            if (tileWidthProportions > 0.78) {
                return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image>
            }
        }

        // if (this.props.view === 'single') {
        //     if (url.match('material-') && url.match('_Diamond')) {
        //         return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
        //     }
            
        //     if (url.match('Oval')) {
        //         return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('Leaf')) {
        //         return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1_4')) {
        //         return <image x="0" y="0" width="160" height="160" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1')) {
        //         return <image x="0" y="0" width="92" height="92" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_1_4')) {
        //         if (tileWidthProportions === 1.1533333333333333) {
        //             return <image x="0" y="0" width={`115`} height={`54`} xlinkHref={`${url}`}></image>
        //         }
        //         if (tileWidthProportions === 1.1483333333333334) {
        //             return <image x="0" y="0" width={`108`} height={`54`} xlinkHref={`${url}`}></image>
        //         }
        //         return <image x="0" y="0" width={`${108 * tileWidthProportions}`} height={`${51 * tileWidthProportions}`} xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_2')) {
        //         if (tileWidthProportions === 1.095) {
        //             return <image x="0" y="0" width={`184`} height={`57`} xlinkHref={`${url}`}></image>
        //         }
        //         // if (tileWidthProportions === 1.1533333333333333) {
        //         //     return <image x="0" y="0" width={`184`} height={`57`} xlinkHref={`${url}`}></image>
        //         // }
        //         return <image x="0" y="0" width={`${162 * tileWidthProportions}`} height={`${51 * tileWidthProportions}`} xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_4')) {
        //         if (tileWidthProportions === 1.095) {
        //             return <image x="0" y="0" width={`372`} height={`57`} xlinkHref={`${url}`}></image>
        //         }
        //         if (tileWidthProportions === 1.1533333333333333) {
        //             return <image x="0" y="0" width={`372`} height={`57`} xlinkHref={`${url}`}></image>
        //         }
        //         return <image x="0" y="0" width={`${329 * tileWidthProportions}`} height={`${51 * tileWidthProportions}`} xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8')) {
        //         if (tileWidthProportions === 1.095) {
        //             return <image x="0" y="0" width={`57`} height={`57`} xlinkHref={`${url}`}></image>
        //         }
        //         if (tileWidthProportions === 1.1533333333333333) {
        //             return <image x="0" y="0" width={`54`} height={`54`} xlinkHref={`${url}`}></image>
        //         }
        //         if (tileWidthProportions === 1.1483333333333334) {
        //             return <image x="0" y="0" width={`54`} height={`54`} xlinkHref={`${url}`}></image>
        //         }
        //         return <image x="0" y="0" width={`${51 * tileWidthProportions}`} height={`${51 * tileWidthProportions}`} xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_2')) {
        //         if (tileWidthProportions === 1.1558333333333333) {
        //             return <image x="0" y="0" width={`54`} height={`54`} xlinkHref={`${url}`}></image>
        //         }
        //         return <image x="0" y="0" width="240" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_4')) {
        //         return <image x="0" y="0" width="490" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_4')) {
        //         return <image x="0" y="0" width="490" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_2')) {
        //         return <image x="0" y="0" width="240" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1.25) {
        //         return <image x="0%" y="0%" width="65" height="65"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1) {
        //         return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions > 0.95) {                
        //         return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
        //     }
        //     if (tileWidthProportions > 0.78) {
        //         return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image> 
        //     }
        // } else if (this.props.view === 'repeat') {
            
        //     if (url.match('material-') && url.match('Diamond')) {
        //         return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('Oval')) {
        //         return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('Leaf')) {
        //         return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1_4')) {
        //         return <image x="0" y="0" width="160" height="160" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1')) {
        //         return <image x="0" y="0" width="92" height="92" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_1_4')) {
        //         return <image x="0" y="0" width="160" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_2')) {
        //         return <image x="0" y="0" width="240" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_4')) {
        //         return <image x="0" y="0" width="490" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_2')) {
        //         return <image x="0" y="0" width="240" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_4')) {
        //         return <image x="0" y="0" width="490" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_4')) {
        //         return <image x="0" y="0" width="490" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_2')) {
        //         return <image x="0" y="0" width="240" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1.25) {
        //         return <image x="0%" y="0%" width="61" height="61"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1) {
        //         return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions > 0.95) {
        //         return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
        //     }
        //     if (tileWidthProportions > 0.78) {
        //         return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image>
        //     }
        // } else if (this.props.view === 'kitchen') {
            
        //     if (url.match('material-') && url.match('Diamond')) {
        //         return <image x="-2px" y="-2.5px" width="181" height="181" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('Oval')) {
        //         return <image x="-12px" y="-180px" style={transformRot90} width="90" height="180px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('Leaf')) {
        //         return <image x="-60px" y="-5px" width="330" height="175px" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1_4')) {
        //         return <image x="0" y="0" width="160" height="160" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_1')) {
        //         return <image x="0" y="0" width="92" height="92" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_1_4')) {
        //         return <image x="0" y="0" width="160" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_2')) {
        //         return <image x="0" y="0" width="240" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_5_8_4')) {
        //         return <image x="0" y="0" width="490" height="75" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_2')) {
        //         return <image x="0" y="0" width="240" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_1_4')) {
        //         return <image x="0" y="0" width="490" height="125" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_4')) {
        //         return <image x="0" y="0" width="490" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (url.match('material_2_2')) {
        //         return <image x="0" y="0" width="240" height="240" xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1.25) {
        //         return <image x="0%" y="0%" width="61" height="61"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions >= 1) {
        //         return <image x="0%" y="0%" width="75" height="75"  xlinkHref={`${url}`}></image>
        //     }
        //     if (tileWidthProportions > 0.95) {
        //         return <image x="-0.2px" y="0%" width="75px" height="75px"  xlinkHref={`${url}`}></image> 
        //     }
        //     if (tileWidthProportions > 0.78) {
        //         return <image x="-1px" y="-1px" width="100" height="100"  xlinkHref={`${url}`}></image>
        //     }
        // }
        setTimeout(() => {
            this.forceUpdate();
        }, 500)

        setTimeout(() => {
            this.forceUpdate();
        }, 2000)
    }

    render() {
        const that = this;
        return (
            <div className="preview">
    
                <div>
                    {Object.keys(this.props.defs).length ? (
                        <svg className="materials" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                            <defs>
                                {(this.props.defs as any).map((material: any, idx: number) => {
                                    const chips = typeof material.chips === 'string' ? JSON.parse(material.chips) : material.chips;
                                    if (chips && chips.length) {
                                        return Array.isArray(chips) && chips.map((url: string, index: any) => {
                                            return <pattern key={`${idx}_${idx}_${index}`} id={chips[index].replace('.png', '').replace(``, '')} x="0%" y="0%" height="100%" width="100%">
                                                {this.generateDefs(url)}
                                            </pattern>
                                        }
                                        )
                                    } else {
                                        return <pattern key={`${idx}_${idx}`} id={material.src.replace('.png', '').replace(``, '')} x="0%" y="0%" height="100%" width="100%">
                                            {/* <image x="0%" y="0%" width="77" height="77" xlinkHref={`${material.src}`}></image> */}
                                            {this.generateDefs(material.src)}
                                        </pattern>
                                    }
                                })}
                            </defs>
                        </svg>
                    ) : (
                            <div></div>
                        )}
                </div>
                {this.props.patterns['pattern'] ? (
                    <div className={`preview__container`}>
                        {(this.state.loading || this.props.previewLoading) && (
                            <div className="loading">
                                <ReactLoading color="#000" type="spinningBubbles" />
                            </div>
                        )}
                        <div className="preview__buttons">
                            <div className={`preview__button ${this.props.view === 'single' ? 'selected' : ''}`} onClick={() => this.handleSingleClick()}>Single</div>
                            <div className={`preview__button ${this.props.view === 'repeat' ? 'selected' : ''} ${this.props.onlySingle ? 'blocked': ''}`} onClick={() => this.handleRepeatClick()}>Repeat</div>
                            <div className={`preview__button ${this.props.view === 'kitchen' ? 'selected' : ''} ${this.props.onlySingle ? 'blocked': ''}`} onClick={() => this.handleRoomClick()}>In Room</div>
                        </div>
                        <div className={`preview__wrapper preview-${this.props.view}`}>
                            <div className={`svg__wrapper ${this.props.view} ${this.props.patterns['pattern'].name.trim().replace(' ', '')}`}>
                                {this.props.patterns['pattern'].src && (
                                    <ReactSVG
                                        className={"singleSVG"}
                                        src={this.props.patterns['pattern'].svg}
                                        renumerateIRIElements={false}
                                    />
                                )}

                                <ReactSVG
                                    className={"repeatSVG"}
                                    src={this.props.patterns['pattern'].pattern_svg || ""}
                                    renumerateIRIElements={false}
                                    // afterInjection={() => {this.handleSingleClick(); return undefined}}
                                />
                                { <Kitchen />}
                            </div>
                        </div>
                        <p className="bottom-note">While our digital tool is very good at approximating the look of your design, our skilled artisans are even better and will create each sheet by hand to ensure that your project is beautiful and cohesive.</p>

                        
                    </div>
                ) : (
                        <div className="placeholder">
                            <h5>Select a pattern to start designing your custom mosaic</h5>
                            <img src={this.props.brand.image ? this.props.brand.image : '/static/images/placeholder.png'} className="placeholder" alt="" />
                        </div>
                    )}

            </div>
        );
    }
}

export default Preview;