import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import ReactLoading from "react-loading";
import axios from 'axios';

import './orderModal.scss';
import { Close } from "./close";
import Select from 'react-select';

export interface OrderModalProps {
    open: any;
    close: any;
    sendOrder: any;
    sample: boolean;
    brand: any;
}

export interface OrderModalState {
    form: any;
    validate: any;
    modalIsOpen: boolean;
    loading: boolean;
    sample: boolean;
    shipAddress: boolean;
    selectedState: any;
}

const styleModal = {
    content: {
        width: 'calc(490px - 60px)',
        maxWidth: '90%',
        maxHeight: '96vh',
        height: 'fit-content',
        top: '50%',
        transform: 'translate(0, -50%)'
    }
} as Modal.Styles

const customStyles = {

    singleValue: (provided: any, state: any) => {
        if (state.getValue() && state.getValue()[0] && state.getValue()[0].value !== 'State...') {
            return { ...provided, ...{ color: '#000 !important' } };
        }
        return { ...provided };
    }
}


class OrderModal extends React.Component<OrderModalProps, OrderModalState> {
    constructor(props: OrderModalProps) {
        super(props);
        this.state = {
            sample: false,
            shipAddress: false,
            selectedState: {},
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                sq_ft: '',
                message: '',
                project_name: '',
                address: '',
                city: '',
                zip: '',
                state: '',
                sample: false,
                quote: false
            },
            validate: {
                first_name: {
                    required: true,
                    type: 'string',
                    error: false
                },
                last_name: {
                    required: true,
                    type: 'string',
                    error: false
                },
                email: {
                    required: true,
                    type: 'email',
                    error: false
                },
                phone: {
                    required: false,
                    type: 'string',
                    error: false
                },
                sq_ft: {
                    required: true,
                    type: 'number',
                    error: false
                },
                zip: {
                    required: false,
                    type: 'zip',
                    error: false
                },
                address: {
                    required: false,
                    type: 'address',
                    error: false
                },
                city: {
                    required: false,
                    type: 'address',
                    error: false
                },
                state: {
                    required: false,
                    type: 'address',
                    error: false
                }
            },
            modalIsOpen: false,
            loading: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpen = this.afterOpen.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.changeShip = this.changeShip.bind(this);
        this.handleState = this.handleState.bind(this);
        this.changeSample = this.changeSample.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
        this.props.close();
    }

    afterOpen() {
        console.log(this.props.sample)
        this.setState({
            loading: false,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                sq_ft: '',
                message: '',
                project_name: '',
                address: '',
                city: '',
                zip: '',
                state: '',
                'sample': this.props.sample,
                quote: !this.props.sample
            },
            selectedState: { value: 'State...', label: 'State...' }
        });
    }

    public validateForm() {
        let errors = false;
        let validate = this.state.validate;
        Object.keys(this.state.validate).forEach((key) => {


            if (validate[key].required && this.state.form[key].trim() === '') {
                validate[key].error = true;
                errors = true;
            } else if (validate[key].type === 'email' && !this.state.form[key].match(/[^@]+@[^\.]+\..+/)) {
                validate[key].error = true;
                errors = true;
            } else if (validate[key].type === 'number' && (isNaN(this.state.form[key]) || /\s/.test(this.state.form[key]))) {
                validate[key].error = true;
                errors = true;
            } else if (validate[key].type === 'number' && this.state.form[key] === '0') {
                validate[key].error = true;
                errors = true;
            } else if (validate[key].type === 'zip' && ((this.props.brand.style === 'internal' && (this.props.sample || this.state.sample)) || (this.props.brand.style === 'other' && this.state.shipAddress)) && !this.state.form[key].match(/^\d{5}$/)) {
                validate[key].error = true;
                errors = true;
            } else if (validate[key].type === 'address' && ((this.props.brand.style === 'internal' && (this.props.sample || this.state.sample)) || (this.props.brand.style === 'other' && this.state.shipAddress)) && this.state.form[key].trim() === '') {
                validate[key].error = true;
                errors = true;
            } else {
                validate[key].error = false;
            }


        })
        this.setState({
            validate
        })
        if (errors) {
            return false;
        }

        return true;
    }

    sendOrder() {

        if (!this.validateForm()) {
            return false;
        }

        if (this.props.brand.style === 'other' && (this.props.sample || this.state.sample) && !this.state.shipAddress) {
            this.setState({
                form: {
                    ...this.state.form,
                    'address': 'Send To Showroom'
                }
            });
        }
        console.log(this.state.form);

        setTimeout(() => {
            this.setState({ modalIsOpen: false, loading: true });
            this.props.sendOrder(this.state.form);

            this.setState({
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    sq_ft: '',
                    message: '',
                    project_name: '',
                    address: '',
                    city: '',
                    zip: '',
                    state: '',
                }
            });
        }, 200)

    }

    handleInput(event: any) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    handleState(value: any) {
        console.log(value);

        this.setState({
            form: {
                ...this.state.form,
                'state': value.value
            },
            selectedState: value
        });
    }

    getStates() {
        const arr = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
        const states = arr.map((item) => { return { value: item, label: item } });
        const result = [{ value: '', label: "State..." }].concat(states);
        return states;
    }

    changeShip(shipAddress: any) {
        this.setState({
            shipAddress
        })
    }

    changeSample(event: any) {
        this.setState({
            sample: !this.state.sample,
            form: {
                ...this.state.form,
                sample: !this.state.sample
            },
            shipAddress: false
        })
    }

    handleArea(event: any) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'message' && value.length > 1500) {
            return false;
        }

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    render() {
        return (
            <div className="order-modal">
                <Modal
                    isOpen={this.props.open}
                    onAfterOpen={this.afterOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    style={styleModal}
                    portalClassName="order-modal-portal"
                >
                    {this.state.loading ? (
                        <div className="loading">
                            <ReactLoading color="#000" />
                        </div>
                    ) : (
                            <div className="order-modal">
                                <div className="close__button close__button--order" onClick={this.closeModal}>
                                    <Close />
                                </div>
                                {this.props.sample && (
                                    <div>
                                        <h2>Get Material Sample</h2>
                                        <p>Wow, that’s a stunning design! Please fill out the short form below and our team will send you a sample of all materials you selected promptly.</p>
                                    </div>
                                )}
                                {!this.props.sample && (
                                    <div>
                                        <h2>Request Quote</h2>
                                        <p>Wow, that’s a stunning design! Please fill out the short form below and our professional team will respond with a quote promptly.</p>
                                    </div>
                                )}
                                <div className="form">
                                    <input type="text" onChange={this.handleInput} className={this.state.validate.first_name.error ? 'error' : ''} name="first_name" placeholder="First Name..." />
                                    {this.state.validate.first_name.error && (
                                        <span>Value Required</span>
                                    )}
                                    <input type="text" onChange={this.handleInput} className={this.state.validate.last_name.error ? 'error' : ''} name="last_name" placeholder="Last Name..." />
                                    {this.state.validate.last_name.error && (
                                        <span>Value Required</span>
                                    )}
                                    <input type="text" onChange={this.handleInput} className={this.state.validate.email.error ? 'error' : ''} name="email" placeholder="Email..." />
                                    {this.state.validate.email.error && (
                                        <span>Value Required</span>
                                    )}
                                    <input type="text" onChange={this.handleInput} className={this.state.validate.phone.error ? 'error' : ''} name="phone" placeholder="Phone..." />

                                    <input type="text" onChange={this.handleInput} maxLength={70} name="project_name" placeholder="Project Name (optional)..." />
                                    <input type="text" onChange={this.handleInput} className={this.state.validate.sq_ft.error ? 'error' : ''} name="sq_ft" placeholder="Sq Ft Needed..." />
                                    {this.state.validate.sq_ft.error && (
                                        <span>Numeric Value Required</span>
                                    )}
                                    <p className="info-tip">Please account for any coverage needed for your project.</p>
                                    <textarea onChange={this.handleInput} maxLength={1500} name="message" rows={4} placeholder="Message" />
                                    {!this.props.sample && (
                                        <div className="checkbox-wrapper">
                                            <label className="container">Get Material Sample
                                        <input type="checkbox" onChange={this.changeSample} checked={this.state.sample} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    )}

                                    {
                                        this.props.brand.style === 'other' &&
                                        ((!this.props.sample && this.state.sample) || this.props.sample) && (
                                            <div className="radios">
                                                <label className="container">
                                                    Pick Up at Showroom
                                            <input type="radio" onChange={e => this.changeShip(false)} name="radio" checked={!this.state.shipAddress} />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="container">
                                                    Ship to Another Address
                                            <input type="radio" onChange={e => this.changeShip(true)} name="radio" checked={this.state.shipAddress} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        )}
                                    {
                                        ((this.props.brand.style === 'other' && ((!this.props.sample && this.state.sample && this.state.shipAddress) || this.props.sample && this.state.shipAddress)) ||
                                            (this.props.brand.style === 'internal' && ((!this.props.sample && this.state.sample) || this.props.sample))) &&
                                        (
                                            <div className="address">
                                                <input type="text" className={this.state.validate.address.error ? 'error' : ''} onChange={this.handleInput} maxLength={70} name="address" placeholder="Shipping Address..." />
                                                {this.state.validate.address.error && (
                                                    <span>Value Required</span>
                                                )}
                                                <input type="text" className={this.state.validate.city.error ? 'error' : ''} onChange={this.handleInput} maxLength={70} name="city" placeholder="City..." />
                                                {this.state.validate.city.error && (
                                                    <span>Value Required</span>
                                                )}
                                                <div className="group">
                                                    {/* <input type="text" className={this.state.validate.state.error ? 'error': ''} onChange={this.handleInput} maxLength={70} name="state" placeholder="State..."/> */}
                                                    <div className={this.state.validate.state.error ? 'error select' : ' select'}>
                                                        <Select
                                                            value={this.state.selectedState}
                                                            onChange={this.handleState}
                                                            options={this.getStates()}
                                                            styles={customStyles}
                                                            className={`select-grout`}
                                                            classNamePrefix={`select-grout`}
                                                        />
                                                    </div>
                                                    {this.state.validate.state.error && (
                                                        <span>Value Required</span>
                                                    )}
                                                    <input type="text" className={this.state.validate.zip.error ? 'error' : ''} onChange={this.handleInput} maxLength={70} name="zip" placeholder="Zip..." />
                                                    {this.state.validate.zip.error && (
                                                        <span>Wrong zipcode</span>
                                                    )}
                                                </div>
                                            </div>

                                        )}

                                    <button onClick={this.sendOrder}>Submit</button>
                                </div>
                            </div>
                        )}
                </Modal>
            </div>
        );
    }
}

export default OrderModal;