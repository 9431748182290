import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faPinterest, faFacebook } from "@fortawesome/free-brands-svg-icons";
import Modal from 'react-modal';
import axios from 'axios';
import './saveModal.scss'
import { faMailBulk, faTimesCircle, faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { Close } from "./close";
import Loading from "react-loading";

export interface SaveModalProps {
    open: any;
    close: any;
    id: string
    brand: any;
    design: any;
}

export interface SaveModalState {
    copy: string;
    patterns_api: any;
    modalIsOpen: boolean;
    loading: boolean;
}


const styleModal = {
    content: {
        width: '40vw',
        height: '400px',
        top: '50%',
        transform: 'translateY(-50%)'
    }
} as Modal.Styles
 
class SaveModal extends React.Component<SaveModalProps, SaveModalState> {
    constructor(props: SaveModalProps) {
        super(props);
        this.state = {
            copy: "Copy",
            patterns_api: {},
            modalIsOpen: false,
            loading: false
        };

        
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handlePDF = this.handlePDF.bind(this);
        this.copy = this.copy.bind(this);
    }

    componentDidMount() {

    }

    openModal() {
        this.setState({modalIsOpen: true});
    }
    
    closeModal() {
        this.setState({modalIsOpen: false});
        this.props.close();
    }

    handlePDF(url: string) {
        this.setState({
            loading: true
        })

        setTimeout(() => {
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank'
            link.click()
            this.setState({
                loading: false
            })
        }, 2500)
    }

    copy(e: any) {
        e.preventDefault();
        (document.querySelector('.url') as any).select();
        document.execCommand('copy');
        this.setState({
            copy: "Copied"
        })

        setTimeout(() => {
            this.setState({
                copy: "Copy"
            })
        }, 3000)
    }

    render() {
        return (
            <Modal
                    isOpen={this.props.open}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    style={styleModal}
                    portalClassName="save-modal-portal"
                >
                    <div className="save-modal">
                        <div className="close__button" onClick={this.closeModal}>
                        <Close />
                        </div>
                        <h2>Design saved</h2>
                        <p>Well, that’s a stunning design! Just copy the link, download a PDF or send the design via email.</p>
                        <div className="type pattern">
                            <div className="input-group">
                                <input type="text" className="url" onChange={() => {}} value={`https://${process.env.REACT_APP_URL}/${this.props.brand.subdomain ? `${this.props.brand.subdomain}` : ''}/design/${this.props.id}`}/>
                                <a href="#" className="copy_url" onClick={this.copy}>{this.state.copy}</a>
                            </div>
                            <div className="share">
                                {this.state.loading ? (
                                    <Loading type="spin" color="#000" width="35px" height="35px"/>
                                ) : (
                                    <a target="_blank" onClick={(e) => this.handlePDF(`https://mosaic-creator.s3.us-west-2.amazonaws.com/designs/${this.props.id}/${this.props.id}.pdf`)}><FontAwesomeIcon size="2x" icon={faDownload} /></a>
                                )}
                                <a target="_blank" href={`https://${process.env.REACT_APP_URL}/${this.props.brand.subdomain? this.props.brand.subdomain : ''}/design/${this.props.id}`}><FontAwesomeIcon size="2x" icon={faLink} /></a>
                                <a target="_blank" href={`https://twitter.com/intent/tweet?text=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain? this.props.brand.subdomain : ''}/design/${this.props.id}`}><FontAwesomeIcon size="2x" icon={faTwitter} /></a>
                                <a target="_blank" href={`https://pinterest.com/pin/create/button/?url=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain? this.props.brand.subdomain : ''}/design/${this.props.id}&description=Mosaic+Creator&media=${this.props.design.design_image}`}><FontAwesomeIcon size="2x" icon={faPinterest} /></a>
                                <a target="_blank" href={`https://www.facebook.com/sharer.php?u=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain? this.props.brand.subdomain : ''}/design/${this.props.id}&p[title]=Mosaic+Creator`}><FontAwesomeIcon size="2x" icon={faFacebook} /></a>
                                <a target="_blank" href={`mailto:?body=http://${process.env.REACT_APP_URL}/${this.props.brand.subdomain? this.props.brand.subdomain : ''}/design/${this.props.id}`}><FontAwesomeIcon size="2x" icon={faMailBulk} /></a>
                            </div>
                        </div>
                        <div className="close__button_bottom" onClick={this.closeModal}>Close</div>
                    </div>
                </Modal>
        );
    }
}
 
export default SaveModal;