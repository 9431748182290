import React from 'react';

export const Kitchen = (props: any) => {

    return (
        <div className="kitchen-wrapper" 
            // style={{
            // display: props.hide ? 'none' : ''
            // }}
            >
            <img src="/static/images/Kitchen.png" alt=""/>
            {/* <div className="images_build"></div> */}
            {/* <svg width="1200px" height="1200px" viewBox="0 0 1200 1200" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 999,
                display: 'block'
            }} version="1.1">
                <defs>
                    <rect id="path-1" x="0" y="0" width="1200" height="1200"></rect>
                </defs>
                <g id="room-preview-kitchen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="mask"></g>
                    <g id="kitchen-scene" mask="url(#mask-2)" stroke="#000000">
                        <g transform="translate(-31.000000, -20.000000)">
                            <rect id="Rectangle" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="2" y="831" width="1627" height="562"></rect>
                            <path d="M1068,2 L1068,545.217666 L1106.66863,570 L1679,570 L1679,2 L1068,2 Z" id="Rectangle" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd"></path>
                            <path d="M2,2 L2,570 L519.008232,570 L550,543.673545 L550,2 L2,2 Z" id="Rectangle-Copy-2" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd"></path>
                            <path d="M1105,372.857759 L1105,570" id="Line" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" strokeLinecap="round"></path>
                            <path d="M520,372.857759 L520,570.857759" id="Line-2" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd"></path>
                            <rect id="Rectangle" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="1103" y="895" width="524" height="36"></rect>
                            <rect id="Rectangle-Copy-2" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="2" y="895" width="548" height="36"></rect>
                            <polygon id="Path-2" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" points="1064.86765 390 1128 361.249696 918.715606 225 500 361.249696 552.334446 390"></polygon>
                            <rect id="Rectangle" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="578" y="778" width="463" height="53"></rect>
                            <path d="M577.729595,831 L531,894.737261 L531,932 L1099.75521,932 L1102.92976,894.820076 L1041.0997,831 L577.729595,831 Z" id="Path-3" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd"></path>
                            <rect id="Rectangle" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="531" y="928" width="572" height="429"></rect>
                            <path d="M1143.5,1325 L1143.5,933" id="Line-3" strokeWidth="4" fill="none"></path>
                            <path d="M324,1242 L324,933" id="Line-3-Copy-2" strokeWidth="4" fill="none"></path>
                            <path d="M490.5,1325 L490.5,933" id="Line-3-Copy-3" strokeWidth="4" fill="none"></path>
                            <path d="M1,1011.5 L323.5,1011.5" id="Line-4-Copy" strokeWidth="4" fill="none"></path>
                            <polygon id="Path" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" points="524.825827 15.4751465 500 361 1128 361 1100.42465 0"></polygon>
                            <rect id="Rectangle-Copy-7" strokeWidth="4" fill="#FFFFFF" fillRule="evenodd" x="531" y="895" width="572" height="36"></rect>
                            <path d="M138.875,962.5 L186.794922,962.5" id="Line-6-Copy-3" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                            <path d="M1204.85714,962.5 L1251.79911,962.5" id="Line-6-Copy-2" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                            <path d="M383.857143,962.5 L430.799107,962.5" id="Line-6-Copy-5" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                            <path d="M138.875,1043.5 L186.794922,1043.5" id="Line-6-Copy-4" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                            <path d="M216.5,543.145367 L216.5,9.70774261" id="Line-7" strokeWidth="4" fill="none" strokeLinecap="square"></path>
                            <path d="M520.144737,543.5 L1,543.5" id="Line-5" strokeWidth="4" fill="none" strokeLinecap="square"></path>
                            <path d="M1710,543.5 L1105,543.5" id="Line-5-Copy" strokeWidth="4" fill="none" strokeLinecap="square"></path>
                            <path d="M189.5,457.828125 L189.5,508.356689" id="Line-8" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                            <path d="M242.5,457.828125 L242.5,508.356689" id="Line-8-Copy" strokeWidth="8" fill="none" strokeLinecap="square"></path>
                        </g>
                    </g>
                </g>
            </svg> */}
            
        </div>
    );
};