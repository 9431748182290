import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from 'axios';
import { Close } from "./close";

export interface PatternProps {
    patterns: any;
    handlePattern: any;
    brand: any
}

export interface PatternState {
    patterns_api: any;
    modalPatternIsOpen: boolean;
    
}

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      width                 : '75vw',
      height                : 'auto',
      marginRight           : '0',
      marginTop             : '0',
      transform             : 'translate(-50%, -50%)',
    maxHeight				: '90vh'
    }
  };

const closeStyle = {
    top: '25px',
    right: '35px',
    // height: '100px',
    // padding: '0 5px',
    // background: '#F2F2F2',
    // borderLeft: '1px solid #ccc',
    // borderBottom: '1px solid #ccc',
    cursor: 'pointer'
} as React.CSSProperties

const headingStyle = {
    fontSize: '12px',
    marginTop: '5px'
} as React.CSSProperties

const headingStyleH2 = {
    fontSize: '24px',
    margin: '5px 0'
} as React.CSSProperties
 
class Pattern extends React.Component<PatternProps, PatternState> {
    constructor(props: PatternProps) {
        super(props);
        this.state = {
            modalPatternIsOpen: false,
            patterns_api: [],
        };

        
        this.openPatternModal = this.openPatternModal.bind(this);
        this.closePatternModal = this.closePatternModal.bind(this);
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/patterns?brand=${this.props.brand}`).then((data) => {
            
            this.setState({
                patterns_api: data.data
            })

            
        })
    }

    openPatternModal() {
        this.setState({modalPatternIsOpen: true});
    }
    
    closePatternModal() {
        this.setState({modalPatternIsOpen: false});
    }

    handlePattern(pattern: any) {
        this.closePatternModal()
        // console.log(pattern);
        
        this.props.handlePattern(pattern)
    }

    render() { 
        let coverage = ''
        if (this.props.patterns['pattern']) {
            coverage = JSON.parse(this.props.patterns['pattern']['specification']).coverage
        }
        return (
            <div className="step pattern">
                <div className="heading">
                    <div className="index">01</div>
                    <div className="name">Pick a Pattern</div>
                    {/* {this.props.patterns['pattern'] && (
                        <div className="coverage">Coverage: <span>{coverage} sq. ft.</span></div>
                    )} */}
                </div>
                <div className="input">
                    <div className="material-select" onClick={() => this.setState({modalPatternIsOpen: true})}>
                        <div className="material pattern">
                            {this.props.patterns['pattern'] && this.props.patterns['pattern'].src && (
                                <div className="information">
                                    <img src={this.props.patterns['pattern'].src} alt=""/>
                                    {this.props.patterns['pattern'] && (
                                        <div className="description">
                                            <div className="pattern-name">{this.props.patterns['pattern'].name}</div>
                                            <div className="coverage">Coverage: <span>{coverage} sq. ft.</span></div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!this.props.patterns['pattern'] && (
                                <div className="text">Select pattern</div>
                            )}
                        </div>
                        <div className="add"><FontAwesomeIcon icon={faChevronDown} /></div>
                    </div>
                    <Modal
                        isOpen={this.state.modalPatternIsOpen}
                        onRequestClose={this.closePatternModal}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                        // style={customStyles}
                        portalClassName={`patterns-modal`}
                    >
                        <div className="close__button" style={closeStyle} onClick={this.closePatternModal}>
                            <Close />
                        </div>
                        <div className="type pattern">
                            <h2 style={headingStyleH2}>Pick a Pattern</h2>
                            <p style={headingStyle}>Choose a design to get started, you’ll be able to choose materials and grout after your selection.</p>
                            <div className="items">
                                {this.state.patterns_api.map((item: any, idx: number) => {
                                    return (
                                        <div className={`item ${this.props.patterns['pattern'] && this.props.patterns['pattern'].id === item.id ? 'used' : ''}`} key={idx} onClick={() => this.handlePattern(item)}>
                                            <img src={item.src} alt=""/>
                                            <p>{item.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                </Modal>
                </div>
            </div>
        );
    }
}
 
export default Pattern;